import React,{useRef,useEffect, useState} from 'react'
import { 
  Typography,
} from "@mui/material";
import {Link} from "react-router-dom"
import { getStorageData } from '../../framework/src/Utilities';
const DownloadImg=require("./downloadIcon.svg").default
const LogoImg=require("./tresorlogo.svg").default;
import {useNavigate} from 'react-router-dom'
const instagramImg=require("./socialmediaicons/instagram.svg").default
const tiktokImg=require("./socialmediaicons/tiktok.svg").default
const xImg=require("./socialmediaicons/x.svg").default
const linkedinImg=require("./socialmediaicons/linkdin.svg").default

export function Footer({categoriesList,handleCategoryData,openLoginModal}:any) {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const navigate =useNavigate()

  const [auth,setAuth]=useState<any>(null)
  const scrollToTop = () => {
    window.scrollBy({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const fetchAuth = async () => {
      const token = await getStorageData('loginToken'); 
      setAuth(token); 
    };

    fetchAuth(); 
  }, [auth]);


  return (
    <>
      <div style={{ width: "100%" }}>
            <div style={{backgroundColor: "#53503F", height:"373px"}}>
        <div
          className="footerLinks"
          style={{
            boxSizing: "border-box",
            padding:"0px 7rem",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#53503F",
            height: "282px",
            margin:"auto",
            position:"relative",
            top:"61px"
          }}
        >
          <img
            style={{
              width: "127.87px",
              height: "105.67px",
              padding: "0.65px 14.21px 14.68px 14.21px",
              cursor:"pointer"
            }}
            onClick={()=>{
              navigate("/")
            }}
            src={LogoImg}
            alt="Logo"
          />
          <div>
            <Typography
              style={{
                color: "#EDE6DC",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              ABOUT US
            </Typography>
            <div
              className="about"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "19px",
              }}
            >
              <span  onClick={()=>{
                navigate("/")
              }} style={{ color: "#EDE6DC", cursor:"pointer",textDecoration: "none",fontFamily: "'Montserrat', sans-serif", }} >
                Home
              </span>
              <span
                style={{cursor:"pointer", color: "#EDE6DC", textDecoration: "none",fontFamily: "'Montserrat', sans-serif", }}
              onClick={()=>{
                if(!auth){
                  openLoginModal()
                }
              }}
              >
                Login/Register
              </span>
            </div>
          </div>

          <div>
            <Typography
              style={{
                color: "#EDE6DC",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              CATEGORIES
            </Typography>
            <div
              className="about"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "19px",
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              {categoriesList?.map((ele:any)=>{
                return <span onClick={()=>{
                  handleCategoryData(ele.name,ele.id)
                }} style={{ color: "#EDE6DC", textDecoration: "none",cursor:"pointer" }}>
               {ele.name}
              </span>
              })}
            </div>
          </div>

          <div>
            <Typography
              style={{
                color: "#EDE6DC",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              SUPPORT
            </Typography>
            <div
              className="about"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "19px",
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              <Link style={{ color: "#EDE6DC", textDecoration: "none" }} to="/">
                My Account
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                My Orders
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Contact Us
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Terms & Conditions
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                Privacy Policy
              </Link>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                FAQs
              </Link>
            </div>
          </div>
          <div>
            <Typography
              style={{
                color: "#EDE6DC",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "30px",
                textAlign: "left",
                marginBottom: "20px",
              }}
            >
              SOCIAL MEDIA
            </Typography>
            <div
              className="about"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "19px",
              }}
            >
              <div style={{ color: "#EDE6DC", textDecoration: "none", display:"flex",gap:"6px" }}>
               <img  width={20} height={19} src={instagramImg} alt="instagram" />
               <img  width={20} height={19} src={linkedinImg} alt={"icon"} />
               <img  width={20} height={19} src={xImg} alt={"icon"} />
               <img  width={20} height={19} src={tiktokImg} alt={"icon"} />
              </div>
              <Link
                style={{ color: "#EDE6DC", textDecoration: "none" }}
                to="/Login"
              >
                <img style={{marginTop:"-19px"}} height={120} width={120} src={DownloadImg} alt="cdc" />
              </Link>
            </div>
          </div>
        </div>
        </div>
        

        <div
          style={{
            fontFamily: "'Montserrat', sans-serif",
            height: "61px",
            width: "100%",
            backgroundColor: "#EDE6DC",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          © 2024 - All Rights Reserved by {"  "}
          <span
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "14px",
              fontWeight: 800,
              lineHeight: "21.87px",
              textAlign: "left",
              marginLeft:"10px"
            }}
          >
            TRÉSOR
          </span>
        </div>
      </div>
    </>
  );
}

const webStyle = {
    footerHeadings:{
      fontFamily: "'Montserrat', sans-serif",
        fontSize: "20px",
        fontWeight: 800,
        lineHeight: "30px",
        textAlign: "left",
      }
};
