import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Typography,
  TextField,
  styled
} from "@mui/material";
import Badge from '@mui/material/Badge';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from "@mui/icons-material/Search";
import ProfilePopUp from './ProfilePopUp.web';
import { useNavigation } from '@react-navigation/native';
import Modal from '@mui/material/Modal';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import { borderRadius } from 'react-select/src/theme';
import { getStorageData } from '../../framework/src/Utilities';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LogOutConfirmationPopUp from '../../components/src/popups/LogOutConfirmationPopUp.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions.web';
import {useNavigate} from 'react-router-dom'
import  EmailAccountRegistrationSeller from '../../blocks/email-account-registration/src/EmailAccountRegistrationSeller.web';
import LoginSuccess from '../src/popups/LoginSuccessPopUp.web';
import OTPInputAuthWeb from '../../blocks/otp-input-confirmation/src/OTPInputAuth.web';
import EmailRegistrationSellerForm2 from '../../blocks/email-account-registration/src/EmailRegistrationSellerForm2.web';
import RegistrationSuccess from '../src/popups/RegistrationSuccessPopUp.web';
import SellerSignupSuccess from '../src/popups/SellerConfirmationPopUp.web';
import FilterWeb from '../../blocks/categoriessubcategories/src/Filter.web';

const chiragImg=require("./chirag.svg").default
const Logo=require("./Lgo.svg").default
const CartIcon=require("./socialmediaicons/cart.svg").default
const closeIcon = require("./image_close.png").default;
const miniLogo = require("./miniLogo.png").default;
const sellWithUs = require("./socialmediaicons/sellWith.svg").default;

const MyTextField = styled(TextField)(({ theme }) => ({
  width: "406px",
  boxSizing: "border-box",
  height: "44px",
  justifyContent: "center",
  padding: "10px 8px",
  gap: 8,
  borderRadius: "8px",
  borderWidth: "0.2px 0 0 0",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
  },
  [theme.breakpoints.down('md')]: {
    width: "300px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
  },
}));

const ResponsiveDiv = styled("div")(({ theme }) => ({
  width: "47vw",
  display: "flex",
  gap: "30px",
  [theme.breakpoints.down('lg')]: {
    width: "320px",
    gap: "20px",
  },
  [theme.breakpoints.down('md')]: {
    width: "280px", 
    marginLeft: "2.5rem",
    gap: "15px",
  },
  [theme.breakpoints.down('sm')]: {
    width: "100%",
    marginLeft: "0",
    gap: "10px",
    justifyContent: "center",
  },
}));
const ResponsiveButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily:"'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
  padding: "10px 20px",
  borderRadius: "8px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  [theme.breakpoints.down('sm')]: {
    fontSize: "14px",
    padding: "8px 16px",
  },
  ".MuiButtonBase-root":{
  fontFamily:"'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
  }
}));

const style = ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  maxHeight: '80vh',
  overflowY: 'auto',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '8px',
  backgroundColor: '#EFEEE9',
  padding: '20px',
  '@media screen and (max-width: 420px)':{
    height: '100%',
    width: '100%',
    borderRadius: 1,
    overflowY: 'scroll'
  },
  '@media screen and (min-width: 1440px)': {
    padding: '10px' 
  }
});
const closeIconStyle = ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '5px',
    '>img:hover': {
  }
})

const useStyles = makeStyles({
  toggleMain: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px',
    width: '208px',
    backgroundColor: '#f5f5f5',
    padding: '5px 0px',
    margin: '10px 0px'
  },
  toggleButtonGroup: {
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
  },
  toggleButton: {
    textDecoration: 'none',
    color: '#4e493d !important',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none !important',
    borderRadius: '5px !important',
    '&.Mui-selected': {
      backgroundColor: '#53503F !important',
      color: '#fff !important',
      borderRadius: 5,
      fontWeight: '600',
    },
  },
  logo: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center'
  }
});


export function AppHeader(props: any) {
  const [showModal, setShowModal] = useState(props.openLoginModal||false); 
  const [auth, setAuth] = React.useState(false);
  const [openForgotModal,setForgotModal]=React.useState(false)
  const [openTermsPopup,setTermsPopup]=React.useState(false)
  const [openPrivacyPopup,setPrivacyPopup]=React.useState(false);
  const [openPrivacyShopper,setOpenPrivacyShopper]=React.useState(false)
  const [openTermsPopupShopper,setopenTermsPopupShopper]=React.useState(false)
  const headerRef = useRef<HTMLHeadingElement | null>(null);  
  let lastScrollY = 0;
  const [sellerSignUpModal, setSellerSignUpModal] = React.useState(false);
  const [loginSuccess, setLoginSuccess] = React.useState(false)
  const [otpSellerModal, setOtpSellerModal] = React.useState(false)
  const [firstFormData, setFirstFormData] = React.useState({})
  const [sellerForm2, setSellerForm2] = React.useState(false)
  const [secondFormToken, setSecondFormToken] = React.useState('')
  const [otpModalBuyer, setOtpModalBuyer] = React.useState(false)
  const [roleName, setRoleName] = React.useState('')
  const [registrationSuccess, setRegistrationSuccess] = React.useState(false)
  const [sellerSuccess, setSellerSuccess] = React.useState(false)
  const navigate =useNavigate()
  const { allFilter, handleCloseAllFilter, categoryPageVisibility,handleApplyButton, categoryId, genderForFilter } = props;
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY; 
  
      if (headerRef.current) {
        if (currentScrollY > lastScrollY && currentScrollY > 50) {
          // Scrolling down
          headerRef.current.classList.add('hidden');  // Add hidden class
          headerRef.current.classList.remove('visible');  // Remove visible class
        } else if (currentScrollY < lastScrollY) {
          // Scrolling up
          headerRef.current.classList.add('visible');  // Add visible class
          headerRef.current.classList.remove('hidden');  // Remove hidden class
        }
        lastScrollY = currentScrollY; // Update last scroll position
      }
    };
  
    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    setShowModal(props.openLoginModal);
  }, [props.openLoginModal]);
  
  useEffect(() => {
    const fetchAuth = async () => {
      const token = await getStorageData('loginToken'); 
      setAuth(token); 
    };

    fetchAuth(); 
  }, [auth]);
  const [authMode, setAuthMode] = useState('login');
  const classes = useStyles();
  const handleNavigation=(route:string)=>{
    navigate(route)
  }
  const handleAuthChange = (event: any, newAuthMode: any) => {
    if (newAuthMode !== null) {
      setAuthMode(newAuthMode);
    }
  };
  const handleOpenTermsModal=async()=>{
   await setTermsPopup(true)
    await setShowModal(false)
    setPrivacyPopup(false);

  }
  const handleCloseTermsModal=()=>{
    setTermsPopup(false);
    setShowModal(true)
  }
  const handleOpenPrivacyModal=async()=>{
    await setPrivacyPopup(true)
  await setShowModal(false)
  await setTermsPopup(false)

  }
  const handleClosePrivacyModal=()=>{
    setPrivacyPopup(false);
    setShowModal(true)
  }
  const handleOpenPrivacyModalShopper=async()=>{
    await setOpenPrivacyShopper(!openPrivacyShopper)
  }
const handlePTermsModalShopper=()=>{
  setopenTermsPopupShopper(!openTermsPopupShopper)
}

  const handleLogin = () => {
    setShowModal(true)
  }
  const handleClose = () => {
    setShowModal(false)
    props.closeLoginModal()
  }
  const handleCloseForgotModal=()=>{
    setForgotModal(false)
  }
  const handlOpeneForgotModal=()=>{
    setForgotModal(true)
    setShowModal(false)
  }
  
  const handleSellerSignupClose = () => {
    setSellerSignUpModal(false)
  }

  const handleSellerModal = () => {
    setSellerSignUpModal(true)
  }

  const handleLoginSuccessModalOpen = () => {
    setLoginSuccess(true)
  }

  const handleLoginSuccessModalClose = () => {
    setLoginSuccess(false)
    window.location.reload();
  }
  
  const OtpSellerModalOpen = () => {
    setSellerSignUpModal(false)
    setOtpSellerModal(true)
  }

  const handleFirstFormToken = (data: any) => {
    if (data.roleName) {
      setRoleName(data.roleName);
      console.log('roleName',data.roleName)
      const { roleName, ...restData } = data;  
      setFirstFormData(restData);
    } else {
      setFirstFormData(data);
    }
  }

  const handleSellerForm2Open = () => {
      setOtpSellerModal(false)
      setSellerForm2(true)
  }

  const handleBuyerForm = () => {
    setRegistrationSuccess(true)
    setOtpModalBuyer(false)
  }

  const handleSellerForm2Close = () => {
    setSellerForm2(false)
  }

  const handleSellerForm2Token = (data: any) => {
    setSecondFormToken(data)
  }

  const handleOtpModalBuyerOpen = () => {
    setShowModal(false)
    setOtpModalBuyer(true)
  }

  const handleRegistrationSuccess = () => {
    setOtpModalBuyer(false)
    setRegistrationSuccess(false)
    window.location.reload(); 
  }

  const handleSellerFormOpen = () => {
    setSellerForm2(false)
    setSellerSuccess(true)
  }
  
  const handleSellerFormClose = () => {
    setSellerSuccess(false)
  }

  const handleCloseOtpSeller = () => {
    setOtpSellerModal(false)
  }

  const handleCloseOtpBuyer = () => {
    setOtpModalBuyer(false)
  }
  
  const handleNavigateHomePage = () => {
    navigate('/')
  }

   return (
    <>
    {allFilter ? <FilterWeb handleFilterClose={handleCloseAllFilter} handleApplyButton={handleApplyButton} categoryId={categoryId} genderForFilter={genderForFilter}/> : null }   
    <div
      ref={headerRef}
      style={
        {
          ...webStyle.container,
          ...webStyle.header,
        } as React.CSSProperties
      }
      className={`${webStyle.header}`}
    >
      <ResponsiveDiv style={{ width: "370px", display: "flex", gap: "30px" }}>
        <ResponsiveButton
          style={{
            ...webStyle.sellwithus,
            backgroundColor: "#EDE6DC",
            fontWeight: 500,
            color: "#53503F",
          }}
          className="bigCaslon"
          onClick={handleSellerModal}
        >
          <img
            width={22}
            height={22}
            style={{ marginRight: "5px" }}
            src={sellWithUs}
            alt="vfv"
          />
          sell with us
        </ResponsiveButton>

        <ResponsiveButton
          style={{
            ...webStyle.sellwithus,
            color: "white",
            backgroundColor: "#53503F",
            border: "none",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
          className="bigCaslon"
          onClick={() => {
            if (!auth) {
              // Redirect to login page if not authenticated
              handleLogin()
            } else {
              // Redirect to Make a Wish page if authenticated
              handleNavigation("RequestManagement");
            }
          }}
        >
          <img
            width={29}
            height={26}
            style={{ marginRight: "5px" }}
            src={chiragImg}
            alt="vfv"
          />
          Make a wish
        </ResponsiveButton>
      </ResponsiveDiv>

      <div style={{position: "absolute",cursor:"pointer", left: "50%", transform: "translateX(-50%)" }} onClick={() => {
        handleNavigation("/");
        categoryPageVisibility()}}>
        <img

          style={{
            backgroundColor: "transparent",
            display: "block",
            cursor:"pointer",
            width: "105px",
            height: "70.35px",
            top: "19.82px",
            left: "40px",
            padding: "0.53px 11.67px 12.06px 11.67px",
          }}
          src={Logo}
          alt="logo"
          onClick={handleNavigateHomePage}
        />
      </div>

      <div
        className="shortcuts"
        style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            justifyContent: "center",
            alignItems: "center",
            cursor:'pointer'
          }}
          onClick={() => {
            if (!auth) {
              handleLogin()
            } else {
              handleNavigation("/ShoppingCart");
            }
          }}
        >
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: "#53503F",
              },
            }}
            badgeContent={props?.count}
            
          >
            <img
              style={{
                width: "24px",
                height: "24px",
                top: "30.25px",
                left: "1203.5px",
                padding: "0.1px 0px 0.09px 0px",
              }}
              src={CartIcon}
              alt="logo"
            />
          </Badge>
          <Typography
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              textAlign: "center",
              color: "#53503F",
            }}
          >
            Cart
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            justifyContent: "center",
            alignItems: "center",
            
          }} 
         
        >
          <FavoriteBorderIcon  onClick={() => {
              if (!auth) {
                handleLogin()
              } else {
                handleNavigation("/Favorites");
              }
            }} style={{ color: "#53503F",cursor:'pointer' }} />
          <Typography
        
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              textAlign: "center",
              color: "#53503F",
              cursor:'pointer'
            }}
            onClick={() => {
              if (!auth) {
                handleLogin()
              } else {
                handleNavigation("/Favorites");
              }
            }}
          >
            Favorites
          </Typography>
        </div>
          <ProfilePopUp showModal={showModal} handleLogin={handleLogin}/>
            <Modal
            open={sellerSignUpModal}
            onClose={handleSellerSignupClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{              
                maxWidth: '100%',
                backdropFilter: "blur(5px)"
            }}
            >
              <Box sx={{...style, width: 800}}>
                <Box className={classes.logo}>
                  <img src={miniLogo} alt='logo'/>
                </Box>
                <EmailAccountRegistrationSeller handleCancelBtn={handleSellerSignupClose} navigation={undefined} id={''} OtpSellerModalOpen={OtpSellerModalOpen} handleFirstFormToken={handleFirstFormToken}
                />
              </Box>
            </Modal>
            <OTPInputAuthWeb navigation={undefined} id={''} openOtpModal={otpSellerModal} handleCloseModal={handleCloseOtpSeller} firstFormData={firstFormData} handleSellerForm2Open={handleSellerForm2Open} handleSellerForm2Token={handleSellerForm2Token}/>
            
            <Modal
            open={sellerForm2}
            onClose={handleSellerForm2Close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{              
                maxWidth: '100%',
                backdropFilter: "blur(5px)",
                display:openPrivacyShopper || openTermsPopupShopper ? "none":""
            }}
            >
              <Box sx={{...style, width: 800}}>
                <Box className={classes.logo}>
                  <img src={miniLogo} alt='logo'/>
                </Box>
                <EmailRegistrationSellerForm2 handleCancelButton={handleSellerForm2Close} handleTermsModal={handlePTermsModalShopper} handleOpenPrivacyModal={handleOpenPrivacyModalShopper} navigation={undefined} id={''} tokenFromOtpScreen={secondFormToken} sellerFormClose={handleSellerFormOpen}/>
              </Box>
            </Modal>
            {
              openPrivacyShopper &&     <TermsConditions
              openModal={openPrivacyShopper}
              handleClose={handleOpenPrivacyModalShopper}
              navigation=""
              id=""
              label="Privacy Policy"
            />
            }
            {openTermsPopupShopper &&
              <TermsConditions
              openModal={openTermsPopupShopper}
              handleClose={handlePTermsModalShopper}
              navigation=""
              id=""
              label="Terms and Conditions"
            />}
            <SellerSignupSuccess showSuccess={sellerSuccess} handleClose={handleSellerFormClose} />
                  <Modal
        open={showModal || openTermsPopup || openPrivacyPopup}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          maxWidth: "100%",
          backdropFilter: "blur(5px)",
          zIndex: (openTermsPopup || openPrivacyPopup)?-99:100,
        }}
      >
        <Box sx={{ ...style, width: authMode === "login" ? 500 : 800 }}>
          <Box sx={!openTermsPopup && !openPrivacyPopup ? {} : { display: "none" }}>
            <Box sx={closeIconStyle}>
              <img src={closeIcon} alt="closeIcon" onClick={handleClose} />
            </Box>
            <Box className={classes.logo}>
              <img src={miniLogo} alt="logo" />
            </Box>
            <Box className={classes.toggleMain}>
              <div className={classes.toggleContainer}>
                <ToggleButtonGroup
                  value={authMode}
                  exclusive
                  onChange={handleAuthChange}
                  className={classes.toggleButtonGroup}
                  aria-label="auth mode"
                >
                  <ToggleButton
                    value="login"
                    className={`${classes.toggleButton} montserratLightGoogle`}
                    style={{ textTransform: "none" }}
                  >
                    Log In
                  </ToggleButton>
                  <ToggleButton
                    value="create"
                    className={classes.toggleButton}
                    style={{ textTransform: "none" }}
                  >
                    Create account
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Box>
            {authMode === "login" ? (
              <EmailAccountLoginBlock
                navigation={undefined}
                id={""}
                handleOpenForgotModal={handlOpeneForgotModal}
                closeModal={handleClose}
                handleLoginSuccessModalOpen={handleLoginSuccessModalOpen}
              />
            ) : (
              <EmailAccountRegistration
                handleOpenTermsModal={handleOpenTermsModal}
                handleOpenPrivacyModal={handleOpenPrivacyModal}
                navigation={undefined}
                id={""}
                handleOtpModalBuyerOpen={handleOtpModalBuyerOpen}
                handleFirstFormToken={handleFirstFormToken}
              />
            )}
          </Box>

          {openTermsPopup && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                zIndex: 10,
              }}
            >
              <TermsConditions
                openModal={openTermsPopup}
                handleClose={handleCloseTermsModal}
                navigation=""
                id=""
                label="Terms and Conditions"
              />
            </Box>
          )}

          {/* Overlay for Privacy Policy */}
          {openPrivacyPopup && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                zIndex: 10,
              }}
            >
              <TermsConditions
                openModal={openPrivacyPopup}
                handleClose={handleClosePrivacyModal}
                navigation=""
                id=""
                label="Privacy Policy"
              />
            </Box>
          )}
        </Box>
      </Modal>
            <OTPInputAuthWeb navigation={undefined} id={''} openOtpModal={otpModalBuyer} handleCloseModal={handleCloseOtpBuyer} firstFormData={firstFormData} handleSellerForm2Open={handleBuyerForm} handleSellerForm2Token={undefined} />
            <RegistrationSuccess showSuccess={registrationSuccess} handleClose={handleRegistrationSuccess}/>
            <LoginSuccess showSuccess={loginSuccess} handleClose={handleLoginSuccessModalClose}/>
        <KeyboardArrowDownIcon style={{ marginLeft: "-17px",marginRight:"-1rem",color:"#53503F",cursor:"pointer" }} />
      </div>
      {openForgotModal && (
        <ForgotPasswordWeb
          handleOpenLogin={handleLogin}
          handleOpenForgotModal={handlOpeneForgotModal}
          handleClose={handleCloseForgotModal}
          openForgotModal={openForgotModal}
          navigation={""}
        />
      )}
      {openTermsPopup && (
        <TermsConditions
          openModal={openTermsPopup}
          handleClose={handleCloseTermsModal}
          navigation={""}
          id=""
          label="Terms and Conditions"
        />
      )}
      {openPrivacyPopup && (
        <TermsConditions
          openModal={openPrivacyPopup}
          handleClose={handleClosePrivacyModal}
          navigation={""}
          id=""
          label="Privacy Policy"
        />
      )}
    </div>
    </>
  );
}

const webStyle = {
  container: {
    display: "flex",
    boxSizing:"border-box",
    flexDirection: "row",
    alignItems: "center", 
    justifyContent: "space-between",
    width: "98%",
    height: "6rem",
    backgroundColor: "#EDE6DC",
    boxShadow: "0px 4px 8px 0px rgba(1, 1, 2, 0.19)",
    padding: "0 2.8rem",
    "@media (max-width: 480px)": {
      flexDirection: "column",
      height: "auto",
    },
  },
  logoDiv: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    "@media (max-width: 480px)": {
      marginLeft: "0",
      justifyContent: "center",
    },
  },
  sellwithus: {
    fontSize: "16px",
    fontFamily: "'Big-Caslon-Medium','Adobe Caslon Pro','LibreCaslonText-Regular',serif !important",
    height: "44px",
    padding: "10px 16px",
    gap: "4px",
    borderRadius: "8px",
    border: "1px solid black",
    "@media (max-width: 480px)": {
      fontSize: "14px",
      padding: "8px 12px",
    },
  },
  header:{  
    position: "sticky",
    top: 0,
    width: "100%",
    color: "white",
    zIndex: 1000,
    padding: "0 2.8rem",
    transition: "transform 0.3s ease-in-out, visibility 0.3s"
  },
  headerHidden :{
    transform: "translateY(-100%)"
  },
  
  headerVisible: {
    transform: "translateY(0)"
  }
};