import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData } from "../../../framework/src/Utilities";
import { convertToBase64 } from '../../../components/src/commanMethods';

interface FileWithPreview extends File {
}

type SelectedFiles = FileWithPreview[];

type Previews = string[];

interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
  token?: string
}

interface CategoryType {
  attributes: any;
  id: number,
  type: string,
  attribute: {
    id: number,
    name: string,
    rank: number,
    created_at: string,
    updated_at: string,
    brands: string,
    logo: string
  }
}

interface CatalogueResponse {
  message: string;
  data: CatalogueData;
}

interface CatalogueData {
  id: string;
  type: string;
  attributes: CatalogueAttributes;
}

interface CatalogueAttributes {
  category_name: string;
  brand_name: string;
  description: string;
  gender: string;
  product_condition: string;
  product_use: string | null;
  product_year_production: number;
  product_material: string;
  product_water_resistance: string;
  status: string;
  created_at: string;
  updated_at: string;
  is_hidden: boolean;
  certificates: Certificate[];
  catalogue_variants: CatalogueVariant[];
}

interface Certificate {
  id: number;
  blob_id: number;
  url: string;
}

interface CatalogueVariant {
  id: string;
  type: string;
  attributes: CatalogueVariantAttributes;
}

interface CatalogueVariantAttributes {
  id: number;
  earning: number;
  catalogue_variant_color: Colour;
  catalogue_variant_size: Size;
  one_size: string | null;
  created_at: string;
  updated_at: string;
  price: string;
  images: Image[];
}

interface Colour {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  color_code: string;
}

interface Size {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  size: string | null;
  width: string | null;
  weight: string | null;
  height: string | null;
  category_id: number;
  scale: string;
}

interface Image {
  id: number;
  blob_id: number;
  url: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  gender: string;
  modalNumber: string;
  productName: string;
  category: string;
  categoryDropdown: boolean;
  subCategory: string;
  subCategoryDropdown: boolean;
  condition: string;
  conditionDropdown: boolean;
  material: string;
  materialDropdown: boolean;
  use: string;
  useDropdown: boolean;
  waterResistance: string;
  waterResistanceDropdown: boolean;
  filteredBrands: CategoryType[];
  searchTerm: string;
  brand: number;
  yearOfProduction: string;
  yearOfProductionDropdown: boolean;
  packages: string;
  packagesDropdown: boolean;
  dustBag: boolean;
  selectedFiles: SelectedFiles,
  errorMessage: string,
  previews: Previews,
  variantPopup: boolean,
  description: string,
  price: number;
  youEarn: number;
  selectColorData: CategoryType[];
  color: string;
  size: string;
  sizeDropdown: boolean;
  categoryData: CategoryType[];
  subCategoryData: CategoryType[];
  brandData: CategoryType[];
  conditionData: CategoryType[];
  materialData: CategoryType[];
  useData: CategoryType[];
  loginToken: string;
  productionYear: number[];
  packagesData: CategoryType[];
  colorData: CategoryType[];
  sizeData: CategoryType[];
  earnData: number;
  selectedFilesVariant: SelectedFiles;
  errorMessageVariant: string;
  previewsVariant: Previews;
  uploadDocumentId: number[];
  variantId: number[];
  formResponse: CatalogueResponse[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start  
  categoryApiCallId: string = "";
  subCategoryApiCallId: string = "";
  brandApiCallId: string = "";
  conditionApiCallId: string = "";
  materialApiCallId: string = "";
  useApiCallId: string = "";
  yearOfProductionApiCallId: string = "";
  packagesApiCallId: string = "";
  colorApiCallId: string = "";
  sizeApiCallId: string = "";
  youEarnApiCallId: string = "";
  documentApiCallId: string = "";
  variantApiCallId: string = "";
  submitApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      gender: '',
      modalNumber: '',
      productName: '',
      category: '',
      categoryDropdown: false,
      subCategory: '',
      subCategoryDropdown: false,
      condition: '',
      conditionDropdown: false,
      material: '',
      materialDropdown: false,
      use: '',
      useDropdown: false,
      waterResistance: '',
      waterResistanceDropdown: false,
      filteredBrands: [],
      searchTerm: '',
      brand: 0,
      yearOfProduction: '',
      yearOfProductionDropdown: false,
      packages: '',
      packagesDropdown: false,
      dustBag: false,
      selectedFiles: [],
      errorMessage: '',
      previews: [],
      variantPopup: false,
      description: '',
      price: 0,
      youEarn: 0,
      selectColorData: [],
      color: '',
      size: '',
      sizeDropdown: false,
      categoryData: [],
      subCategoryData: [],
      brandData: [],
      conditionData: [],
      materialData: [],
      useData: [],
      loginToken: '',
      productionYear: [],
      packagesData: [],
      colorData: [],
      sizeData: [],
      earnData: 0,
      selectedFilesVariant: [],
      errorMessageVariant: '',
      previewsVariant: [],
      uploadDocumentId: [],
      variantId: [],
      formResponse: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.handleCategoryApi()
    this.handleBrandApi()
    this.handleConditionApi()
    this.handleMaterialApi()
    this.handleUseApi()
    let loginTokenFromStorage = await getStorageData('loginToken')
    this.setState({loginToken: loginTokenFromStorage},() => {this.handleProductYearApi()})
    this.handlePackagesApi()
    this.handleColorApi()
    this.handleSizeApi()
    // this.handleYouEarnApi()
     // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiHandlers = {
        [this.categoryApiCallId]: this.handleCategoryData,
        [this.subCategoryApiCallId]: this.handleSubCategoryData,
        [this.brandApiCallId]: this.handleBrandData,
        [this.conditionApiCallId]: this.handleConditionData,
        [this.materialApiCallId]: this.handleMaterialData,
        [this.useApiCallId]: this.handleUseData,
        [this.yearOfProductionApiCallId]: this.handleYearOfProductData,
        [this.packagesApiCallId]: this.handlePackagesData,
        [this.colorApiCallId]: this.handleColorData,
        [this.sizeApiCallId]: this.handleSizeData,
        [this.youEarnApiCallId]: this.handleYouEarnData,
        [this.documentApiCallId]: this.handleDocumentUploadData,
        [this.variantApiCallId]: this.handleVariantUploadData,
        [this.submitApiCallId]: this.handleSubmitApiData
      };
      
      if (apiRequestCallId && responseJson) {
        const handler = apiHandlers[apiRequestCallId];
        if (handler) {
          handler.call(this, responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCategoryData = async (response: any) => {
    if(response && response.data){
      this.setState({categoryData: await response.data})
    }
  }

  handleSubCategoryData = async (response: any) => {
    if(response && response.data){
      this.setState({subCategoryData: await response.data})
    }
  }

  handleBrandData = async (response: any) => {
    if(response && response.data){
      this.setState({filteredBrands: await response.data})
    }
  }

  handleConditionData = async (response: any) => {
    this.setState({conditionData: await response.data})
  }

  handleMaterialData = async (response: any) => {
    this.setState({materialData: await response.data})
  }

  handleUseData = async (response: any) => {
    this.setState({useData: await response.data})
  }
  
  handleYearOfProductData = async (response: any) => {
    this.setState({productionYear: await response.years})
  }

  handlePackagesData = async (response: any) => {
    this.setState({packagesData: await response.data})
  }

  handleColorData = async (response: any) => {
    this.setState({selectColorData: await response.data})
  }

  handleSizeData = async (response: any) => {
    this.setState({sizeData: await response.data})
  }

  handleYouEarnData = async (response: any) => {
    this.setState({youEarn: await response.earning})
  }

  handleDocumentUploadData = async (response: any) => {
    this.setState({uploadDocumentId: [...this.state.uploadDocumentId, response.blob.id]})
  }

  handleVariantUploadData = async (response: any) => {
    this.setState({variantId: [...this.state.variantId, response.response[0].attachment_id]})
  }

  handleSubmitApiData = async (response: any) => {
    this.setState({formResponse: response})
  }

  handleGenderChange = (event: React.MouseEvent<HTMLElement>,newValue: string | null) => {
    if (newValue) {
      this.setState({ gender: newValue });
    }
  }

  handleProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({productName: event.target.value})
  }

  handleCategory = (event: SelectChangeEvent) => {
    this.setState({category: event.target.value}, () => this.handleSubCategoryApi())
  }

  handleCategoryDropdown = () => {
    this.setState((prevState) => ({ categoryDropdown: !prevState.categoryDropdown })
  )};

  handleCategoryOpen = () => {
    this.setState({categoryDropdown: true})
  }

  handleCategoryClose = () => {
    this.setState({categoryDropdown: false})
  }

  handleSubCategory = (event: SelectChangeEvent) => {
    this.setState({subCategory: event.target.value})
  }

  handleSubCategoryDropdown = () => {
    this.setState((prevState) => ({ subCategoryDropdown: !prevState.subCategoryDropdown })
  )};

  handleSubcategoryOpen = () => {
    this.setState({subCategoryDropdown: true})
  }

  handleSubcategoryClose = () => {
    this.setState({subCategoryDropdown: false})
  }

  handleCondition = (event: SelectChangeEvent) => {
    this.setState({condition: event.target.value})
  }

  handleConditionDropdown = () => {
    this.setState((prevState) => ({ conditionDropdown: !prevState.conditionDropdown })
  )};

  handleConditionOpen = () => {
    this.setState({conditionDropdown: true})
  }

  handleConditionClose = () => {
    this.setState({conditionDropdown: false})
  }

  handleMaterial = (event: SelectChangeEvent) => {
    this.setState({material: event.target.value})
  }

  handleMaterialDropdown = () => {
    this.setState((prevState) => ({ materialDropdown: !prevState.materialDropdown })
  )};

  handleMaterialOpen = () => {
    this.setState({materialDropdown: true})
  }

  handleMaterialClose = () => {
    this.setState({materialDropdown: false})
  }

  handleUse = (event: SelectChangeEvent) => {
    this.setState({use: event.target.value})
  }

  handleUseDropdown = () => {
    this.setState((prevState) => ({ useDropdown: !prevState.useDropdown })
  )};

  handleUseOpen = () => {
    this.setState({useDropdown: true})
  }

  handleUseClose = () => {
    this.setState({useDropdown: false})
  }

  handleWaterResistance = (event: SelectChangeEvent) => {
    this.setState({waterResistance: event.target.value})
  }

  handleWaterResistanceDropdown = () => {
    this.setState((prevState) => ({ waterResistanceDropdown: !prevState.waterResistanceDropdown })
  )};

  handleWaterResistanceOpen = () => {
    this.setState({waterResistanceDropdown: true})
  }

  handleWaterResistanceClose = () => {
    this.setState({waterResistanceDropdown: false})
  }

  handleSearchChange = (newInputValue: string) => {
    this.setState({ searchTerm: newInputValue });
  };

  handleSelectBrand = (selectedBrand: any) => {
    this.setState({ 
      searchTerm: selectedBrand ? selectedBrand.name : '',
      brand: selectedBrand ? selectedBrand.id : '' 
    });
  };

  handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({description: event.target.value})
  }

  handleModalNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({modalNumber: event.target.value})
  }
  
  handleYearOfProduction = (event: SelectChangeEvent) => {
    this.setState({yearOfProduction: event.target.value})
  }

  handleYearProductionDropdown = () => {
    this.setState((prevState) => ({ yearOfProductionDropdown: !prevState.yearOfProductionDropdown })
  )};

  handleYearProductionOpen = () => {
    this.setState({yearOfProductionDropdown: true})
  }

  handleYearProductionClose = () => {
    this.setState({yearOfProductionDropdown: false})
  }

  handlePackages = (event: SelectChangeEvent) => {
    this.setState({packages: event.target.value})
  }

  handlePackagesDropdown = () => {
    this.setState((prevState) => ({ packagesDropdown: !prevState.packagesDropdown })
  )};

  handlePackagesOpen = () => {
    this.setState({packagesDropdown: true})
  }

  handlePackagesClose = () => {
    this.setState({packagesDropdown: false})
  }

  handleDustBag = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({dustBag: event.target.checked})
  }

  validateAndSetFiles = (files: File[]) => {
    const validFiles: File[] = [];
    const invalidFiles: string[] = [];
    const previews: string[] = [];
    let errorMessage = '';

    files.forEach(async (file) => {
      const fileType = file.type;

      const isValidType = fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf';

      if (isValidType) {
        validFiles.push(file);

        previews.push(URL.createObjectURL(file));
        const base64Image = await convertToBase64(file);
        this.handleDocumentUploadApi(base64Image)
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      errorMessage = `Invalid files: ${invalidFiles.join(', ')}`;
    }

    this.setState({
      selectedFiles: [...this.state.selectedFiles, ...validFiles],
      previews: [...this.state.previews, ...previews],
      errorMessage,
    });
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      this.validateAndSetFiles(Array.from(files));
    }
  };

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      this.validateAndSetFiles(Array.from(files));
    }
  };

  handleRemoveFile = (index: number) => {
    const { selectedFiles, previews } = this.state;
  
    const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
    const newPreviews = previews.filter((_, i) => i !== index);
  
    this.setState({
      selectedFiles: newSelectedFiles,
      previews: newPreviews,
    });
  };

  handleVariantPopupOpen = () => {
    this.setState({variantPopup: true})
  }

  handleVariantPopupClose = () => {
    this.setState({variantPopup: false})
  }

  handlePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const price = value ? parseFloat(value) : 0; 
    this.setState({ price },() => this.handleYouEarnApi(price));
  }

  handleEarn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const youEarn = value ? parseFloat(value) : 0;
    this.setState({youEarn})
  }

  handleSize = (event: SelectChangeEvent) => {
    this.setState({size: event.target.value})
  }

  handleSizeDropdown = () => {
    this.setState((prevState) => ({ sizeDropdown: !prevState.sizeDropdown })
  )};

  handleSizeOpen = () => {
    this.setState({sizeDropdown: true})
  }

  handleSizeClose = () => {
    this.setState({sizeDropdown: false})
  }

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, token } = data
    const header = {
      'Content-Type': contentType,
      'Token': token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleCategoryApi = async () => {
    this.categoryApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.categoryEndPoint}`,
    });
  }

  handleSubCategoryApi = async () => {
    const queryParamsObj: any = { category_id: this.state.category };
    const queryParams = new URLSearchParams(queryParamsObj).toString();

    this.subCategoryApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.subCategoryEndPoint}?${queryParams}`,
    })
  }

  handleBrandApi = async () => {
    this.brandApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.brandEndPoint}`,
    });
  }
  
  handleConditionApi = async() => {
    this.conditionApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.conditionEndPoint}`,
    }) 
  }

  handleMaterialApi = async() => {
    this.materialApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.materialEndPoint}`
    })
  }

  handleUseApi = async() => {
    this.useApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.useEndPoint}`
    })
  }

  handleProductYearApi = async() => {
    const queryParamsObj: any = { token: this.state.loginToken };
    const queryParams = new URLSearchParams(queryParamsObj).toString();
  
    this.yearOfProductionApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.yearOfProductionEndPoint}?${queryParams}`
    })
  }
  
  handlePackagesApi = async () => {
    this.packagesApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.packagesEndPoint}`
    })
  }

  handleColorApi = async () => {
    this.colorApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.colorEndPoint}`
    })
  }

  handleSizeApi = async () => {
    const queryParamsObj: any = { category_id: this.state.category };
    const queryParams = new URLSearchParams(queryParamsObj).toString();

    this.sizeApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.sizeEndPoint}?${queryParams}`
    })
  }

  handleYouEarnApi = async (userPrice: number) => {
    const queryParamsObj: any = { price: userPrice };
    const queryParams = new URLSearchParams(queryParamsObj).toString();
    
    this.youEarnApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.youEarnEndPoint}?${queryParams}`
    })
  }

  validateAndSetFilesVariant = (files: File[]) => {
    const validFiles: File[] = [];
    const invalidFiles: string[] = [];
    const previewsVariant: string[] = [];
    let errorMessage = '';

    files.forEach(async (file) => {
      const fileType = file.type;

      const isValidType = fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf';

      if (isValidType) {
        validFiles.push(file);

        previewsVariant.push(URL.createObjectURL(file));
        const base64Image = await convertToBase64(file);
        this.handleDocumentVariantApi(base64Image)
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      errorMessage = `Invalid files: ${invalidFiles.join(', ')}`;
    }

    this.setState({
      selectedFilesVariant: [...this.state.selectedFilesVariant, ...validFiles],
      previewsVariant: [...this.state.previewsVariant, ...previewsVariant],
      errorMessageVariant: errorMessage,
    });
  };

  handleDropVariant = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      this.validateAndSetFilesVariant(Array.from(files));
    }
  };

  handleDragOverVariant = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleFileChangeVariant = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      this.validateAndSetFilesVariant(Array.from(files));
    }
  };

  handleRemoveFileVariant = (index: number) => {
    const { selectedFilesVariant, previewsVariant } = this.state;
  
    const newSelectedFilesVariant = selectedFilesVariant.filter((_, i) => i !== index);
    const newPreviewsVariant = previewsVariant.filter((_, i) => i !== index);
  
    this.setState({
      selectedFilesVariant: newSelectedFilesVariant,
      previewsVariant: newPreviewsVariant,
    });
  };

  handleDocumentUploadApi = async (imageUrl: any) => {
    const body = {
      "platform_type": "desktop",
      "blob": imageUrl,
      "filename": "attachment.png",
      "file_type": "image/png"
    }

    this.documentApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: `${configJSON.uploadDocumentEndPoint}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });
  }

  handleDocumentVariantApi = async (imageUrl: any) => {
    const body = {
      "platform_type": "desktop",
      "blob": [{
        "image": imageUrl,
        "filename": "attachment.jpg",
        "file_type": "image/jpg"
    }]
    }

    this.variantApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: `${configJSON.uploadVariantEndPoint}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });
  }

  handleAddProduct = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    const body = {
      "catalogue": {
          "status": "pending",
          "gender": this.state.gender,
          "name": this.state.productName,
          "category_id": Number(this.state.category),
          "sub_category_id": Number(this.state.subCategory),
          "brand_id": Number(this.state.brand),
          "product_condition": this.state.condition,
          "product_material": this.state.material,
          "product_water_resistance": this.state.waterResistance,
          "description": this.state.description,
          "product_model_number": this.state.modalNumber,
          "product_year_production": this.state.yearOfProduction,
          "packages": this.state.packages,
          "dust_bag": this.state.dustBag,
          "catalogue_variants_attributes": [
              {
                  "catalogue_variant_color_id": 9,
                  "catalogue_variant_size_id": 17,
                  "price": 100,
                  "earning": 70,
                  "attachment_ids": [87]
              }
          ],
          "certificates": this.state.uploadDocumentId
      }
    }
  
    this.submitApiCallId = await this.apiCall({
      method: configJSON.apiMethodTypePost,
      endPoint: `${configJSON.uploadVariantEndPoint}`,
      token: this.state.loginToken,
      body: body,
      contentType: 'application/json'
    });

  }
  // Customizable Area End
}
