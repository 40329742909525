import React from "react";
// Customizable Area Start
import {
  Button,
  Box,
  styled,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  Paper,
  Modal 
} from "@mui/material";


import ShoppingCartController, {
  Props,
configJSON,
countryInterArr,
} from "./ShoppingCartController.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { Footer } from "../../../components/src/Footer.web";
import FullWidthTabComponent from "../../../components/src/LandingPageTabList";
import {addIcon, crossIcon, rightIcon,locationIcon,checkedRadio,uncheckedRadio, lockIcon, shoppingTrollyIcon, visaIcon, klarnaIcon, group_maestro, paypalIcon, googlepayIcon, applepayIcon, bagIcon, heartIcon, searchIcon, checkBoxIcon, checkedIcon, favHeartIcon} from "./assets"
import Select from "react-select";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
// Customizable Area End


export default class ShoppingCart extends ShoppingCartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    handleAddressModal = () => {
        return (
            <Box >
                <Modal
                    open={this.state.isAddressModalOpen}
                    onClose={() => this.setState({ isAddressModalOpen: false })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='isAddressModalOpen'
                   sx={{backdropFilter: "blur(5px)"}}
                >
                    <AddressModalBox >
                        <Box data-test-id='crossIconBox' className="crossIconBox" onClick={() => this.setState({ isAddressModalOpen: false })}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography style={styles.add_address}>{configJSON.add_address}</Typography>
                        <Box className='gridInputBox'>
                            <Box className='gridInputBoxChiled'>
                                <Box className='TwoInputBox'>
                                    {this.handleReturnInput(configJSON.address_Line_1, configJSON.yourAddress, this.state.address_linr_1, `address_linr_1`)}
                                    {this.handleReturnInput(configJSON.address_Line_2, configJSON.yourAddress, this.state.address_linr_2, `address_linr_2`)}
                                </Box>
                                <Box className='TwoInputBox'>
                                    {this.handleReturnSelect(this.state.countryListArray, configJSON.country, configJSON.Select, this.state.country_id, 'country_id')}
                                    {this.handleReturnSelect(this.state.stateListArray, configJSON.state, configJSON.Select, this.state.state, 'state')}
                                </Box>
                                <Box className='TwoInputBox'>
                                    {this.handleReturnSelect(this.state.cityListArray, configJSON.city, configJSON.Select, this.state.city, 'city')}
                                    {this.handleReturnInput(configJSON.postal_Code, configJSON.enterHere, this.state.postal_code, `postal_code`)}
                                </Box>
                                <Box className='TwoInputBox TwoInputBox1'>
                                    <Box className='singleInputParentBox'>
                                        <LabelText >
                                            {configJSON.phoneNumber}
                                        </LabelText>
                                        <PhoneNumStyle
                                            sx={{ border: this.state.phoneNumberErr ? '1px solid #DC2626' : '' }}
                                            data-test-id="txtInputPhonenumber"
                                            className="custominput"
                                            value={this.state.phoneNumber}
                                            defaultCountry={this.state.country_id?.countryCode as "AE"}
                                            countries={[]}
                                            international
                                            onChange={(event) => this.handleChangePhoneNumber(event)}
                                            countrySelectProps={{
                                                tabIndex: -1, 
                                                disabled: true,
                                              }}
                                        />
                                       {this.state.phoneNumberErr&&<Typography data-test-id='phoneNumberErr' sx={styles.errorText}>{configJSON.phoneErr}</Typography>}
                                    </Box>
                                </Box>
                                <Box className='TwoInputBox TwoInputBox1'>
                                    <Box className='makeAddressDefaultCheckBoxParent'>
                                        <img data-test-id='checkBoxImage' onClick={() => this.setState({ isChecked: !this.state.isChecked })} src={this.state.isChecked ? checkedIcon : checkBoxIcon} alt="" /> <span className="makeAddressDefaultCheckText">{configJSON.make_this_address_as_default}</span>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <SaveButton data-test-id='saveButton' onClick={() => this.handleSubmitAddress()} variant="contained" disabled={this.checkForDisableSave()}>{configJSON.save}</SaveButton>
                    </AddressModalBox>
                </Modal>
            </Box>
        )
    }
    handleReturnInput = (label: string, placeholder: string, stateValue: string, state: string) => {
        return (
            <Box className='singleInputParentBox'>
                <LabelText >
                    {label}
                </LabelText>
                <InputField
                    fullWidth
                    error={state=='postal_code'&&this.state.postal_codeErr}
                    helperText={state=='postal_code'&&this.state.postal_codeErrText}
                    placeholder={placeholder}
                    variant="outlined"
                    value={stateValue.trimStart()}
                    onChange={(event) => { this.handleChangeInput(event.target.value, state) }}
                    data-test-id='inputField'
                />
            </Box>
        )
    }
    
    handleReturnSelect = (optionArrList: countryInterArr[], label: string, placeholder: string, stateValue: countryInterArr | {} | undefined | null, state: string) => {
        return (
            <Box className='singleInputParentBox'>
                <LabelText >
                    {label}
                </LabelText>
                <ReactSelect
                    options={optionArrList}
                    placeholder={placeholder}
                    data-testid="countryCode"
                    value={stateValue}
                    onChange={(event) => this.handleChangeSelect(event, state)}
                    data-test-id='selectField'
                    isDisabled={optionArrList.length==0}
                    classNamePrefix={'custom-select'}
                    styles={{
                       
                        menu: (provided) => ({
                            ...provided,
                            border:'1px solid #53503F',
                            boxShadow:'0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                            zIndex:10000,
                            borderRadius:'4px',
                            marginTop:'3.93px',
                            overflow:'hidden',
                           
                          }),
                          option: (provided,state) => ({
                            ...provided,
                              cursor: 'pointer', 
                              gap:'8px',
                              boxSizing: 'border-box',
                              backgroundColor: state?.isSelected ? '#53503F' : 'unset',
                              paddingRight: '16px',
                              paddingLeft:'16px',
                              paddingBottom:'12px',
                              paddingTop:'12px',
                              fontWeight: state.isSelected ? 600 : 400,
                              color:state.isSelected?'#FFFFFF':'#3C3E49',
                              fontFamily: "'Montserrat', sans-serif",
                              fontSize: '12px',
                              "&:hover": {
                                  backgroundColor: state?.isSelected ? '#53503F' : '#E0E0E0',
                              }
                                            
                          }),
                        
                      }}
                />
            </Box>
        )
    }
    handleCartRemoveModal=()=>{
        return (
            <Box >
                <Modal
                    open={this.state.isCartRemoveModalOpen}
                    onClose={() => this.setState({ isCartRemoveModalOpen: false ,cartIdRemove:'',uuId:''})}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    data-test-id='removeCartModal'
                >
                    <RemoveCartModalBox >
                        <Box data-test-id='crossIconBox2' className="crossIconBox" onClick={() => this.setState({ isCartRemoveModalOpen: false ,cartIdRemove:'',uuId:''})}>
                            <img src={crossIcon} alt="" />
                        </Box>
                        <Typography style={styles.confirmation}>{configJSON.confirmation}</Typography>
                           <Typography className="areYouSureText">{configJSON.are_you_sure_you_want_to_remove}</Typography>
                        <CancelRemoveButtonBox>
                        <CancelButton data-test-id='cancelButton' onClick={() => this.setState({ isCartRemoveModalOpen: false,cartIdRemove:'',uuId:'' })} variant="contained" >{configJSON.cancel}</CancelButton>
                        <RemoveButton data-test-id='removeButton' onClick={()=>this.handleRemoveItemCart()} variant="contained" >{configJSON.remove}</RemoveButton>
                        </CancelRemoveButtonBox>
                    </RemoveCartModalBox>
                </Modal>
            </Box>
        )
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
     
        <ContainerBox >
            <AppHeader count={this.state.cartData?.length}/>
            {this.state.cartData?.length == 0 && <FullWidthTabComponent
                categoriesOnHoverList={this.state.categoriesOnHoverListData}
                getCategoriesOnHover={this.getCategoriesOnHoverData}
                categoriesList={this.state.categoriesArrayData}
                handleCategories={this.handleCategoryData}
                data-test-id='fullWidthTabComponent'
                handleMoveToHome={() => {}}
            />}
            {this.state.cartData?.length == 0 && <SearchParentBox>
                <Box className='SearchChiledBox'>
                    <AutocompleteComponentSearch
                        options={[]}
                        freeSolo
                        id="free-solo"
                        data-test-id='searchInputOuter'
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                margin="normal"
                                placeholder={configJSON.search_for}
                                data-test-id='searchInput'
                                InputProps={{
                                    ...params.InputProps, type: 'search', startAdornment: (
                                        <img src={searchIcon} />
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
            </SearchParentBox>}
            <OuterMainBox >
                {this.state.cartData?.length > 0 && <ArrowTopBox>
                    <Typography data-test-id='home' onClick={()=>this.handleNavigate('Home')} className="arrowHomeText">{configJSON.homeText}</Typography>
                    <img src={rightIcon} alt="rightIcon" />
                    <Typography className="arrowSecondText"> {configJSON.shoppingCartHeading} </Typography>
                </ArrowTopBox>}
                {this.state.cartData?.length > 0 && <MainBox>
                    <MainBoxLeft >
                        <QuantiyBox>
                            <Typography className="QuantiyLeftText">{configJSON.shoppingCartHeading} <span>({this.handleReturnZero(this.state.cart?.data?.attributes?.total_items)}{this.state.cart?.data?.attributes?.total_items})</span></Typography>
                            <Typography className="QuantiyRightText">{this.state.cart?.data?.attributes?.total_items} {configJSON.itemsText}</Typography>
                        </QuantiyBox>
                        <CardMainBox>
                            {this.state.cartData?.length > 0 && this.state.cartData?.map((element:any, index:any) => (
                                <>
                                    <CardBox key={index} >
                                        {element?.attributes?.is_offer_available && <CardGrayBox>
                                            <Typography  className="cardGrayBoxText">{configJSON.listed_Price} {element?.attributes?.price}</Typography>
                                            <Typography data-test-id='approvedPrice' className="cardGrayBoxText">{configJSON.approved_Offer} {element?.attributes?.approved_price}</Typography>
                                        </CardGrayBox>}
                                        <CardSecondBox>
                                            <CardBoxUpperBox>
                                                <CardImageNameBox className="cardImageNameBox" data-test-id="productDescription" onClick={()=>this.props.navigation.navigate("ProductDescription",{productId:element?.attributes?.catalogue_id})}>
                                                    <ImageBox>
                                                        <img src={element?.attributes?.image_url} alt="" />
                                                    </ImageBox>
                                                    <TextBox >
                                                        <Typography className="nameText">{element?.attributes?.name}</Typography>
                                                        <Typography className="imageDesc1">{element?.attributes?.description}</Typography>
                                                        <Typography className="imageDesc">{configJSON.model_Number}{element?.attributes?.model_number}</Typography>
                                                        <Typography className="imageDesc imageDesc2"><span>{configJSON.colorText} </span> <Box style={styles.colorBox}></Box></Typography>
                                                    </TextBox>
                                                    <img data-test-id='crossIconSmallScreen' onClick={()=>this.setState({isCartRemoveModalOpen:true,cartIdRemove:element?.attributes?.id,uuId:this.state.cart?.data?.attributes?.uuid})}className="crossIconSmallScreen" src={crossIcon} alt="" style={styles.crossIcon} />

                                                </CardImageNameBox>
                                                <CardPricingQuantityBox>
                                                    <SizeBox>
                                                        <Typography className='sizeQuantityHeading'>{configJSON.sizeText}</Typography>
                                                        <Typography className="sizeText" >{element?.attributes?.variant_size}</Typography>
                                                    </SizeBox>
                                                    <QuantityMainBox>
                                                        <Typography className='sizeQuantityHeading'> {configJSON.quantityText}</Typography>
                                                        <QuantityButtonBox>
                                                            <Typography data-test-id='addQuantity' onClick={()=>this.handleAddQuantityCart(this.state.cart?.data?.attributes?.uuid,element?.id)} className="incrDecrButton">+</Typography>
                                                            <Typography className="quantityNumber" > {element?.attributes?.quantity}</Typography>
                                                            <Typography data-test-id='removeQuantity' onClick={()=>this.handleRemoveQuantityCart(this.state.cart?.data?.attributes?.uuid,element?.id,element?.attributes?.quantity)} className="incrDecrButton">-</Typography>
                                                        </QuantityButtonBox>
                                                    </QuantityMainBox>
                                                    <Typography  data-test-id='offerPrice' className='priceHeading'>{element?.attributes?.is_offer_available ? element?.attributes?.approved_price : element?.attributes?.price}</Typography>
                                                    <CrossIconBox ><img data-test-id='crossIconBigScreen' onClick={()=>this.setState({isCartRemoveModalOpen:true,cartIdRemove:element?.attributes?.id,uuId:this.state.cart?.data?.attributes?.uuid})} src={crossIcon} alt="" style={styles.crossIcon} /></CrossIconBox>
                                                </CardPricingQuantityBox>
                                            </CardBoxUpperBox>
                                            <Typography data-test-id='moveToFav' onClick={()=>this.setState({cartIdRemove:element.attributes.id,uuId:this.state.cart.data.attributes.uuid},()=>this.handleMoveToFavorite(element.attributes.catalogue_variant_id))} className="cartBoxBottomText">{configJSON.move_to_Favorites}</Typography>
                                        </CardSecondBox>

                                    </CardBox>
                                    <Typography data-test-id='moveToFavMobile' onClick={()=>this.setState({cartIdRemove:element.attributes.id,uuId:this.state.cart.data.attributes.uuid},()=>this.handleMoveToFavorite(element.attributes.catalogue_variant_id))}  className="cartBoxBottomTextMobile">{configJSON.move_to_Favorites}</Typography>
                                </>
                            ))}
                        </CardMainBox>
                    </MainBoxLeft>
                    <MainBoxRight>
                        <AdressMainBox>
                            <AddresHeadingButtonBox>
                                <Typography className="addressHeading">{configJSON.address}</Typography>
                                <Button onClick={() => this.setState({
                                    country_id: null, 
                                    cityListArray: [],
                                    city: null,
                                    stateListArray: [],
                                    state: null,
                                    address_linr_1:'',
                                    address_linr_2:'',
                                    postal_code:'',
                                    postal_codeErr:false,
                                    postal_codeErrText:'',
                                    phoneNumber:'',
                                    phoneNumberErr:false,
                                    isAddressModalOpen: true,
                                    isChecked:false

                                       })} data-test-id='addressButton' className="addButton" variant="contained" startIcon={<img src={addIcon} />}>{configJSON.add_address}</Button>
                            </AddresHeadingButtonBox>
                            {this.state.addressList?.length > 0 && this.state.addressList.map((element, index) => (
                                <AddressBox1 style={{ borderBottom: index !== this.state.addressList?.length - 1 ? '0.5px solid rgba(83, 80, 63, 0.5)' : '' }}>
                                    <LocationAddressBox>
                                        <img src={locationIcon} alt="" />
                                        <Box className='adress'>
                                            {element?.attributes?.is_activated && <Typography className="addressText1">{configJSON.default}</Typography>}
                                            <Typography className="addressText2">{configJSON.shippingAddress}</Typography>
                                            <Typography className="addressText3">{element?.attributes?.address_line_1 || element?.attributes?.address_line_2}{element?.attributes?.address_line_1 && ',' || element?.attributes?.address_line_2 && ','} {element?.attributes?.postal_code} {element?.attributes?.city!=='nil'&&element?.attributes?.city}</Typography>
                                        </Box>
                                    </LocationAddressBox>
                                     <img data-test-id='radioCheckIcon' height={19} width={19} onClick={()=>this.setState({selectedAddressId:element.id})} src={this.state.selectedAddressId===element.id ? checkedRadio : uncheckedRadio} className="checkedRadioId" alt="checkedRadio" />
                                </AddressBox1>))}

                        </AdressMainBox>
                        <CalculationMainBox>
                            <Typography className="summarText">{configJSON.summary}</Typography>

                            <Box className='totalMainBox'>
                                <Typography className="totalTypo"><span className="keyText">{configJSON.subtotal}</span><span className="keyValue">{this.state.cart?.meta?.summary?.subtotal_price}</span></Typography>
                                <Typography className="totalTypo"><span className="keyText">{configJSON.delivery}</span><span className="keyValue">{this.state.cart?.meta?.summary?.delivery_charge}</span></Typography>
                                <Typography className="totalTypo"><span className="keyText">{configJSON.taxes}</span><span className="keyValue">{this.state.cart?.meta?.summary?.tax_percentage}</span></Typography>
                                <Typography className="totalTypo"><span className="keyText">{configJSON.authentication_Fee}</span><span className="keyValue">{this.state.cart?.meta?.summary?.platform_fees}</span></Typography>
                                <Divider className="devider" />
                                <Typography className="totalTypo"><span className="totalKey">{configJSON.total}</span><span className="totalKey">{this.state.cart?.meta?.summary?.total_price}</span></Typography>
                            </Box>
                            <Typography className="secureCheckoutText"><span><img className="lockIcon" src={lockIcon} alt="lockIcon" /></span><span className="secureCheckoutTextSpan2">{configJSON.secureCheckoutText}</span></Typography>
                            <Box className='checkoutSecurelyButtonMainBox'>
                                <Box className='checkoutSecurelyButtonMainBox2'>
                                    <Button className="checkoutSecurelyButton" variant="contained" startIcon={<img src={shoppingTrollyIcon} alt='' />} >{configJSON.checkout_Securely}</Button>
                                    <PayOptionIconBox >
                                        {[visaIcon, klarnaIcon, group_maestro, paypalIcon, googlepayIcon, applepayIcon].map((image, index) => (
                                            <img key={index} className='payOptionIcons' src={image} alt={image} />
                                        ))}
                                    </PayOptionIconBox>
                                </Box>
                            </Box>
                        </CalculationMainBox>
                    </MainBoxRight>
                </MainBox>}
                {this.state.cartData?.length == 0 && <EmptyCartMainBox>
                    <EmptyBagMainBox>
                        <Box className='emptyImageText'>
                            <img className="bagIcon" src={bagIcon} alt="" />
                            <Box className='emptyTextBox'>
                                <Typography className='emptyText'>{configJSON.yourCartIsEmpty}</Typography>
                                <Typography className='emptyText2'>{configJSON.looksLikeYouHaventMadeYourChoiseYet}</Typography>
                                <Button data-test-id='startShopping' className="startShopping" onClick={()=>this.handleNavigate('Home')} variant="contained">
                                    {configJSON.startShopping}
                                </Button>
                            </Box>
                        </Box>
                    </EmptyBagMainBox>
                    <ExploreCardBoxMain>
                        <Typography className="exploreMoreText">{configJSON.exploreMore}</Typography>
                        <Box className='emptyCardOuter'>
                            {this.state.emptyCartData?.length > 0 && this.state.emptyCartData?.map((element) => (
                                (element?.catalogues?.length > 0 && element?.catalogues.map((item, index) => (
                                    <EmptyCardBox data-test-id='cardMain' className='cardMain' key={index} onClick={()=>this.props.navigation.navigate("ProductDescription",{productId:item.id})} >
                                           
                                        <Box className='cardImageWhiteBox'>
                                            <img className="cardImage" src={item?.attributes?.image_url} alt="" />
                                            <img className="heartIcon" data-test-id='heartIconFav'
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    this.handleAddToFavorite(item?.id)
                                                }}
                                                src={item?.attributes?.is_favorited ? favHeartIcon : heartIcon} alt="" />
                                        </Box>

                                        <Box className='cardBottumBox'>
                                            <Typography className="brandText">{item?.attributes?.brand}</Typography>
                                            <Typography className="descText">{item?.attributes?.description}</Typography>
                                            <Typography className="priText">{item?.attributes?.price}</Typography>
                                        </Box>

                                    </EmptyCardBox>
                                )))))}
                        </Box>
                    </ExploreCardBoxMain>
                </EmptyCartMainBox>}
            </OuterMainBox>
            {this.handleAddressModal()}
            {this.handleCartRemoveModal()}
            <Footer />
        </ContainerBox>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ContainerBox = styled(Box)({
    backgroundColor: '#EDE6DC',
})
const OuterMainBox = styled(Box)({
    padding: '41px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    backgroundColor: '#EDE6DC',
    boxSizing: 'border-box',
    '@media (max-width: 560px) ': {
        padding: '24px',
    }

})
const ArrowTopBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '11px',
    paddingLeft:'6px',
    "& .arrowHomeText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        fontWeight: 400,
        color: '#53503F',
        cursor: 'pointer'
    },
    "& .arrowSecondText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        fontWeight: 600,
        color: '#53503F'
    }

})
const MainBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    gap: '19px',
    '@media (max-width: 1400px) ': {
        alignItems: 'center',
        flexDirection: 'column',
        width: 'fit-content',
        alignSelf: 'center'
    },
    '@media (max-width: 560px) ': {
        width: '100%'

    },
})
const MainBoxLeft = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    width:'100%',
    maxWidth:'985.5px',
    '@media (max-width: 1400px) ': {
        width: '100%'

    },
})
const QuantiyBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "& .QuantiyLeftText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '28px',
        fontWeight: 600,
        color: '#53503F'
    },
    "& span": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '28px',
        fontWeight: 500,
        color: '#53503F'
    },
    "& .QuantiyRightText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        fontWeight: 600,
        color: '#53503F'
    },
    '@media (max-width: 560px) ': {
        "& .QuantiyLeftText": {
            fontSize: '18px',
        },
        "& span": {
            fontSize: '18px',
            fontWeight: 400,
        },
        "& .QuantiyRightText": {
            fontSize: '16px',
        },
    },
})
const CardMainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    "& .cartBoxBottomTextMobile": {
        display: 'none'
    },
    '@media (max-width: 1400px)': {
        gap: '12px',
        "& .cartBoxBottomTextMobile": {
            display: 'block',
            alignSelf: 'end',
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '18px',
            fontWeight: 400,
            textAlign: 'right',
            color: '#53503F',
            cursor: 'pointer',
            paddingTop: '27px',

        },

    }
})
const CardBox = styled(Box)({
    border: '1px solid #53503F',
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '8px',
    '@media (max-width: 1400px)': {
        borderRadius: '8px',
    }
})
const CardGrayBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px 8px 0 0',
    backgroundColor: '#EFEEE9',
    padding: '18px',
    boxSizing: 'border-box',
    gap:'20px',
    "& .cardGrayBoxText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 600,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: '#53503F',
        maxWidth:'250px',

    },
    '@media (max-width: 560px)': {
        "& .cardGrayBoxText": {
            fontSize: '12px',
        }
    }
})
const CardSecondBox = styled(Box)({
    padding: '40px',
    boxSizing: 'border-box',
    "& .cartBoxBottomText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        fontWeight: 400,
        color: '#53503F',
        textAlign: 'right',
        cursor: 'pointer',
        display: 'block'
    },
    '@media (min-width: 1400px) and (max-width: 1450px) ': {
        padding: '20px',
    },
    '@media (max-width: 1400px) ': {
        padding: '12px',
        "& .cartBoxBottomText": {
            display: 'none'
        }

    },
})
const CardBoxUpperBox = styled(Box)({
    display: 'flex',
    gap: '41px',
    justifyContent: 'space-between',
    '@media (max-width: 1400px) ': {
        alignItems: 'center',
        flexDirection: 'column',
        gap: '15px',
    },
})

const CardImageNameBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '25px',
    position:'relative',
    
    "& .crossIconSmallScreen": {
        display: 'none'
    },
    '@media (max-width: 1400px) ': {
        width: '100%',
        "& .crossIconSmallScreen": {
            display: 'block',
            position: 'absolute',
            right: 0,
            top: 0,
        }
    },
    '@media (max-width: 560px) ': {
        gap: '9px',
        width: '100%'
    },
})
const ImageBox = styled(Box)({
    height: '120px',
    width: '120px',
    borderRadius: '4px',
    backgroundColor: '#EDE6DC',
    padding: '23px',
    boxSizing: 'border-box',
    "& img": {
        width: '100%',
        height: '100%',
        objectFit:'contain'
    }
})
const TextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    maxWidth: '250px',
    '@media (min-width: 1400px) and (max-width: 1500px) ': {
        maxWidth: '201px',

    },
    "& .nameText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '22px',
        fontWeight: 600,
        color: '#53503F',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    "& .imageDesc1": {
        fontSize: '18px',
        fontFamily: "'Montserrat', sans-serif",
        color: '#53503F',
        fontWeight: 400,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    "& .imageDesc": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 400,
        color: '#53503F',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    "& .imageDesc2": {
        display: 'flex',
        alignItems: 'center',
        gap: '6px'
    },
    '@media (max-width: 1400px) ': {
        maxWidth: '236px',
    },
    '@media (max-width: 420px) ': {
        width: '150px',
        maxWidth:'120px',
        "& .nameText": {
            fontSize: '14px',
        },
        "& .imageDesc1": {
            fontSize: '12px',
        },
        "& .imageDesc": {
            fontSize: '10px',
        },
    },
    
    
    '@media (max-width: 480px) ': {
        width: '180px',

    },
})
const CardPricingQuantityBox = styled(Box)({
    display: 'flex',
    gap: '41px',
    maxWidth:'413px',
    "& .priceHeading": {

        fontFamily: "'Montserrat', sans-serif",
        fontSize: '24px',
        fontWeight: 600,
        color: '#53503F',
        lineHeight: 'unset',
        width: '100px',
        whiteSpace: 'nowrap' as 'nowrap',
        overflow: "hidden",
        textOverflow: "ellipsis",
        

    },
    "& .sizeQuantityHeading":{
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '16px',
            fontWeight: 400,
            color: '#53503F'
    },
    '@media (max-width: 1400px) ': {
        width: '100%',
        justifyContent: 'space-between'
    },
    '@media (max-width: 420px) ': {
        gap: 'unset',
        "& .priceHeading": {
            fontSize: '16px',
            width: '60px',
            textAlign:'center'
        },
        "& .sizeQuantityHeading":{
            fontSize: '12px',
        }
    },
})
const SizeBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    height: 'fit-content',
    "& .sizeText": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        color: '#53503F',
        fontWeight: 600,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: '94px'
    },
    '@media (max-width: 420px) ': {
        "& .sizeText": {
            fontSize: '12px',
        }
    },
})
const CrossIconBox = styled(Box)({
    display: 'block',
    cursor:'pointer',
    height:'fit-content',
    '@media (max-width: 1400px) ': {
        display: 'none'
    },
})
const QuantityMainBox = styled(Box)({
    height: 'fit-content',
    display: 'flex',
    gap: '12px',
    flexDirection: 'column',
})
const QuantityButtonBox = styled(Box)({
    border: '1px solid #EDE6DC',
    display: 'flex',
    alignItems: 'center',
    "& .incrDecrButton": {
        width: '37.34px',
        height: '29.7px',
        backgroundColor: '#EDE6DC',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '20px',
        fontWeight: 600,
        color: '#53503F',
        cursor: 'pointer'
    },
    "& .quantityNumber": {
        width: '37.34px',
        height: '29.7px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        color: '#53503F',
        fontWeight: 600,
    },
    '@media (max-width: 420px) ': {
        height: '21.15px',
        "& .quantityNumber": {
            fontSize: '12.81px',
        },
        "& .incrDecrButton": {
            fontSize: '14.24px',
            width: '26.58px',
            height: '21.15px'
        }
    },
})
const MainBoxRight = styled(Box)({
    marginTop: '71px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    '@media (max-width: 1400px) ': {
        marginTop: '20px',
        gap: '20px',
        width: '100%'
    }
})
const AdressMainBox = styled(Box)({
    border: '1px solid #53503F',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    padding: '30px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    "@media (max-width: 1400px)": {
        backgroundColor: 'unset',
        border: 'none',
        padding: '30px 0 30px 0',
    }
})
const AddresHeadingButtonBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '16px',
    paddingBottom: '16px',
    boxSizing: 'border-box',
    "@media (max-width: 1400px)": {
        borderTop: '0.5px solid rgba(83, 80, 63, 0.5)',
        borderBottom: '0.5px solid rgba(83, 80, 63, 0.5)',
    },
    "& .addButton": {
        textTransform: 'none',
        borderRadius: '4px',
        backgroundColor: '#53503F',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        fontWeight: 400,
        color: '#EDE6DC',
        cursor: 'pointer'
    },
    "& .addButton:hover": {
        cursor: 'pointer',
        backgroundColor: '#53503F',
    },
    "& .addressHeading": {

        fontFamily: "'Montserrat', sans-serif",
        fontSize: '14px',
        fontWeight: 600,
        color: '#53503F'
    }

})
const AddressBox1 = styled(Box)({
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    gap: '40px',

    "& .checkedRadioId": {
        cursor: 'pointer'
    },
    "@media (max-width: 1400px)": {
        borderBottom: '0.5px solid rgba(83, 80, 63, 0.5)'

    },
})

const LocationAddressBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    "& .adress": {
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
    },

    "& .addressText1": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#53503F'
    },
    "& .addressText2": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        fontWeight: 500,
        color: '#53503F',
        lineHeight: 'normal'
    },

    "& .addressText3": {
        lineHeight: 'normal',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        fontWeight: 400,
        color: '#53503F',
        width: '247px',
        wordWrap: 'anyWhere',
        maxWidth: 'fit-content'

    },
    "@media (max-width: 380px)": {
        "& .addressText3": {
            width: 'unset',
        }

    }
})
const CalculationMainBox = styled(Box)({
    border: '1px solid #53503F',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    "& .summarText": {
        color: "#000000",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '24px',
        fontWeight: 600,
        padding: '30px',
        boxSizing: 'border-box',
        borderBottom: '0.5px solid rgba(83, 80, 63, 0.5)'
    },
    "& .totalMainBox": {
        padding: '30px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    "& .totalTypo": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    "& .devider": {
        width: '100%',
        color: 'rgba(83, 80, 63, 0.5)'

    },
    "& .keyText": {
        color: "#0F172A",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 400,
    },
    "& .keyValue": {
        color: "#0F172A",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontWeight: 600,
    },
    "& .totalKey": {
        color: "#0F172A",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '22px',
        fontWeight: 600,
    },
    "& .secureCheckoutText": {
        display: 'flex',
        backgroundColor: '#EFEEE9',
        padding: '12px 30px 12px 30px',
        gap: '10px'

    },
    "& .secureCheckoutTextSpan2": {

        color: "#53503F",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '14px',
        fontWeight: 600,

    },
    "& .lockIcon": {
        height: '16px'
    },
    "& .checkoutSecurelyButtonMainBox": {
        padding: '30px',
        boxSizing: 'border-box'
    },
    "& .checkoutSecurelyButtonMainBox2": {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        boxSizing: 'border-box',
        gap: '20px'
    },
    "& .checkoutSecurelyButton": {
        borderRadius: '4px',
        textTransform: 'none',
        fontFamily: "'Montserrat', sans-serif",
        backgroundColor: '#53503F',
        fontSize: '18px',
        color: '#EDE6DC',
        fontWeight: 600,
        cursor: 'pointer',
        height: '56px'
    },

    "& .checkoutSecurelyButton:hover": {
        backgroundColor: '#53503F',
        cursor: 'pointer',
    },
    '@media (max-width: 1400px)': {
        border: 'none'
    }

})
const PayOptionIconBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    paddingRight: '11.5px',
    paddingLeft: '11.5px',
    boxSizing: 'border-box',
    '@media (max-width: 560px) ': {
        display: 'unset',
        textAlign: 'center'
    }
})
const EmptyCartMainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '140px',

})
const EmptyBagMainBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    "& .emptyImageText": {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px'
    },
    "& .emptyTextBox": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '15px'
    },
    "& .emptyText": {
        textAlign: 'center',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '25px',
        fontWeight: 600,
        color: '#53503F',
    },
    "& .emptyText2": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'center',
        fontSize: '20px',
        color: '#53503F',
        fontWeight: 400,
        maxWidth: '338px'
    },
    "& .startShopping": {
        textTransform: 'none',
        borderRadius: '4px',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '18px',
        backgroundColor: '#53503F',
        color: '#EFEEE9',
        fontWeight: 600,
        cursor: 'pointer',
        width: '283px',
    },
    "& .startShopping:hover": {
        backgroundColor: '#53503F',
    },
    "@media  (max-width:500px)": {
        '& .startShopping': {
            width: 'unset',
            fontWeight: 500,
            fontSize: '14px',
        },
        "& .emptyText": {
            fontSize: '16px',
        },
        "& .emptyText2": {
            fontSize: '14px',
        },
        "& .bagIcon":{
            height:'100px',
            width:'100px'
        }
    }
})
const ExploreCardBoxMain = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    "& .exploreMoreText": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        fontSize: '30px',
        color: '#757366',
        fontWeight: 600,
    },
    "& .emptyCardOuter": {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        gap: '55px',

    },
    "@media (max-width:1470px)": {
        "& .emptyCardOuter": {
            gap: '25px',
        }

    },
    "@media (max-width:1230px)": {
        "& .emptyCardOuter": {
            gridTemplateColumns: 'repeat(3,1fr)',
        }

    },
    "@media (max-width:935px)": {
        "& .emptyCardOuter": {
            gridTemplateColumns: 'repeat(2,1fr)',
        }

    },
    "@media (max-width:660px)": {
        "& .emptyCardOuter": {
            gridTemplateColumns: 'repeat(1,1fr)',
            padding: '0 56px 0 56px'
        }
    },
    "@media (max-width:600px)": {
        "& .emptyCardOuter": {
            display: 'none'
        },
        "& .exploreMoreText": {
            display: 'none'

        }
    },

})
const EmptyCardBox = styled(Box)({

    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    "& .cardImageWhiteBox": {
        padding: '70px 45px 70px 45px',
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
    },
    "& .cardBottumBox": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'center',
        gap: '12px',
        padding: '0 0px 20px 0',
        boxSizing: 'border-box',
        maxWidth: '300px'
    },
    "& .cardImage": {
        height: '210px',
        width: "210px",
        objectFit: 'contain'
    },
    "& .heartIcon": {
        position: 'absolute',
        top: '15px',
        right: '15px',
        cursor: "pointer"
    },

    "& .brandText": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        fontSize: '22px',
        color: '#53503F',
        fontWeight: 600,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    "& .descText": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        fontSize: '16px',
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: '#53503F',
        fontWeight: 400,
    },
    "& .priText": {
        fontFamily: "'Montserrat', sans-serif",
        textAlign: 'left',
        fontSize: '20px',
        color: '#53503F',
        whiteSpace: "nowrap",
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    "@media (max-width:1380px)": {
        "& .cardImageWhiteBox": {
            padding: '50px 25px 50px 25px',
        }
    }
})
const SearchParentBox = styled(Box)({
    padding: '0 41px 0 41px',
    boxSizing: 'border-box',
    "& .SearchChiledBox": {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px 0 30px 0',
        boxSizing: 'border-box',
        
    }
})
const CustomPaper = styled(Paper)({
    marginTop: '5px',
    marginBottom: '5px',
    borderRadius: '8px',
    border: '1px solid #53503F',
    color: '#53503F'
});
const AutocompleteComponentSearch = styled(Autocomplete)({
    width: "480px",

    "& .MuiAutocomplete-endAdornment": {
        display: 'none'
    },

    "& .MuiFormControl-marginNormal": {
        marginBottom: "unset",
        marginTop: "unset",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: "0px 0px 0px 8px"
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: "10px 8px 10px 8px",
        marginBottom: '-4px'
    },
    "& .MuiOutlinedInput-root": {
        height: "44px",

        "&:hover fieldset": {
            border: "0.2px solid rgba(83, 80, 63, 1)",
        },
        "&.Mui-focused fieldset": {
            border: "0.2px solid rgba(83, 80, 63, 1)",
        },
    },
    "& .MuiInputBase-root": {
        color: "rgba(137, 135, 123, 1)",
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {

        border: "0.2px solid rgba(83, 80, 63, 1)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "0.2px solid rgba(83, 80, 63, 1)",
        borderRadius: '25px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)'

    },

})
const RemoveCartModalBox=styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.89)',
    width: '600px',
    borderRadius: '4px',
    padding: '68px 49px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EFEEE9',
    height: '340px',
    justifyContent: 'center',
    maxHeight: '340px',
    alignItems: 'center',
    
    "& .areYouSureText": {
        fontSize: '20px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 400,
        color: '#53503F',
        marginTop:'8px',
        maxWidth:'340px',
        textAlign:"center"
    },
    "& .crossIconBox": {
        position: 'absolute',
        right: '28px',
        top: '28px',
        cursor:'pointer'
    },
    "@media  (max-width:550px)": {
        width: 'unset',
    },
    '@media (max-width:400px )': {
        padding: '68px 25px',

    },
    '@media (max-width:362px )': {
        maxHeight: 'unset',
        height: 'unset',
    },
    '@media (max-width:359px )': {
        width: '100%',
    },
})
const AddressModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.89)',
    width: '766px',
    borderRadius: '4px',
    padding: '50px 37.5px',
    boxSizing: 'border-box',
    backgroundColor: '#EFEEE9',
    display: 'flex',
    flexDirection: 'column',
    height: '729px',
    maxHeight: '729px',
    justifyContent: 'center',
    alignItems: 'center',
    "& .TwoInputBox": {
        display: 'flex',
        gap: '25px',
        alignItems: 'center',
    },
    '& .gridInputBox': {
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '50px 0px'
    },
    '& .gridInputBoxChiled': {
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
    },

    "& .crossIconBox": {
        alignSelf: 'end',
        cursor: 'pointer'
    },
    "& .makeAddressDefaultCheckBoxParent": {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '14px',
        color: '#53503F',
        fontWeight: 400,
        "& img": {
            cursor: 'pointer',
            height: '24px',
            width: '24px'
        }
    },
    "@media (min-width:500px) and (max-width:780px)": {
        '& .gridInputBox': {
            width: '80%'
        },
        '& .gridInputBoxChiled': {
            width: '100%'
        }
    },
    "@media  (max-width:500px)": {
        '& .gridInputBox': {
            width: '100%'
        },
        '& .gridInputBoxChiled': {
            width: '100%'
        }
    },
    "@media (max-width:780px)": {
        padding: '20px 20px',
        maxHeight: 'unset',
        height: "unset",
        transform: 'translate(-50%, -50%) scale(0.77)',
        width: '95%',
        "& .TwoInputBox": {
            flexDirection: 'column',
            gap: '20px',
        },
        "& .TwoInputBox1": {
            alignItems: 'start'
        },
        '& .gridInputBox': {
            gap: '20px',
            margin: '20px 0px'
        },
        "& .singleInputParentBox": {
            width: '100%'

        }
    },
    "@media (max-width:370px)": {
        width: '125%',
        "& .singleInputParentBox": {
        }
    }
})
const InputField = styled(TextField)({
    minWidth: "327px",
    height: '56px',

    '& input::placeholder': {
        color: '#BABABA',
        opacity: 1,
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
    },

    "& .MuiInputBase-root": {
        color: "#53503F",
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
        height: '100%'
    },

    "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0px",

    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
        },
        "&.Mui-focused fieldset": {
            borderWidth: "0px",

        },
    },

    "& .MuiOutlinedInput-input": {
        padding: "17px 8px",
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        boxSizing: 'border-box',
        height: '100%'

    },

    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px",
        fontWeight: 600,
        lineHeight:'normal',
        fontSize: '12px',
        fontFamily: "'Montserrat', sans-serif",
        color: "#DC2626",
    },
    '& .css-1wc848c-MuiFormHelperText-root.Mui-error':{
        color: "#DC2626",
        margin:'0px',
        marginTop:'0.5px'
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        border:'1px solid #DC2626'
    },
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",

    },
});
const CancelRemoveButtonBox=styled(Box)({
    display:'flex',
    alignItems:'center',
    gap:'50px',
    marginTop:'40px',
    '@media (max-width:420px )': {
        gap:'28.45px',
    },
    '@media (max-width:359px )': {
        flexDirection:'column'
    },
})
const RemoveButton=styled(Button)({
    width: '155px',
    height: '56px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#EDE6DC',
    cursor: 'pointer',
    "&:hover": {
        backgroundColor: '#53503F',
    },
})
const CancelButton=styled(Button)({
    width: '155px',
    border:'1px solid rgba(83, 80, 63, 1)',
    backgroundColor: 'transparent',
    height: '56px',
    borderRadius: '4px',
    textTransform: 'none',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: '18px',
    cursor: 'pointer',
    color: '#53503F',
    "&:hover": {
        backgroundColor: 'transparent',
    },
})
const SaveButton = styled(Button)({
    width: '360px',
    height: '56px',
    backgroundColor: '#53503F',
    textTransform: 'none',
    borderRadius: '4px',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '18px',
    fontWeight: 600,
    color: '#EDE6DC',
    cursor: 'pointer',
    '&.Mui-disabled': {
        backgroundColor: '#53503F',
        color: '#EDE6DC',
        opacity: 0.5,
        cursor: 'not-allowed'
    },
    "&:hover": {
        backgroundColor: '#53503F',
    },
    "@media (max-width:780px)": {
        width: '80%'
    },
    "@media  (max-width:500px)": {
        width: '100%'
    }

})
const LabelText = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    color: '#53503F',
    fontWeight: 600,
    fontSize: '16px',
    marginBottom: '4px'
})
const ReactSelect = styled(Select)({
    minWidth: '327px',
    outline: 'none',
    height:'56px',
    scrollbarGutter:'stable',
   
    '& .custom-select__menu-list': {
        scrollbarWidth: '5px',  scrollbarColor: '#53503F transparent',  
        direction: 'ltr',
        borderRadius:'8px'
    },
    '& .custom-select__menu-list::-webkit-scrollbar': {
        scrollbarWidth: '5px',
        scrollbarColor: '#53503F transparent',
        direction: 'ltr',
    },
    '& .custom-select__menu-list::-webkit-scrollbar-thumb': {
        scrollbarColor: '#53503F transparent',
        scrollbarWidth: '5px',
        direction: 'ltr',
        borderRadius:'8px'
    },

    '& .custom-select__menu-list:hover': {
        scrollbarWidth: '5px',
        direction: 'ltr',
        scrollbarColor: '#53503F transparent',
        borderRadius:'8px'
      },
    "& .css-1uccc91-singleValue": {
        color: "#53503F",
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: "'Montserrat', sans-serif",
    },
    "& .css-1okebmr-indicatorSeparator": {
        display: 'none'
    },
    "& .css-yk16xz-control": {
        border: 'none',
        borderColor: 'none',
        height:'100%',
    },
    "& .css-yk16xz-control:hover": {
        border: 'none',
        borderColor: 'none'

    },
    "& .css-g1d714-ValueContainer": {
        height: '56px',
        border: 'none'

    },
    "& .css-1pahdxg-control": {
        borderColor: 'transparent !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        border: 'none !important',
        height:'56px',
        minHeight:'56px'
    },
    "& .custom-select__control": {
        minHeight: '56px',
        height: '56px',
      },
    "& .custom-select__value-container": {
        height: '56px',
    },
    "& .css-1wa3eu0-placeholder": {
        color: '#BABABA',
        fontWeight: 400,
        opacity: 1,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        
    },
    "& .css-1hb7zxy-IndicatorsContainer": {
        width: '24px',
        display: 'flex',
        justifyContent: "center"
    },
    "& .css-tlfecz-indicatorContainer": {
        padding: '0'
    },
    "& .css-6q0nyr-Svg": {
        color: '#53503F',
         cursor:'pointer'
    },
    "& .css-26l3qy-menu": {
        fontWeight: 400,
        color: '#3C3E49',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        zIndex: 1000
    },
    "& .css-9gakcf-option": {
        backgroundColor: '#53503F',
        fontWeight: 600,
        color: '#FFFFFF',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
    },
    "& span":{
        display:'none'
    },
    "& svg":{
        cursor:'pointer',
        position: 'absolute',
        right: '8px',
        top: '20px',
        color:'#53503F'
    },

    "@media (max-width: 400px)": {
        minWidth: 'unset'
    }
})
const PhoneNumStyle = styled(PhoneInput)({

    minWidth: "327px",
    boxSizing: "border-box",
    height: '56px',
    zIndex: 1,
    borderRadius: 4,
    position: "relative",
    display: 'flex',
    alignItems: 'center',
    color: "#53503F",
    paddingLeft: "8px",
    backgroundColor: '#FFFFFF',

    '& input': {
        border: 'none',
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        color: "#53503F",
    },
    '& input:focus': {
        border: 'none',
        outline: 'none'
    },
    '& .PhoneInputInput': {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        color: "#53503F",
    },
    '& input:focus-visible': {
        border: 'none',
        outline: 'none'
    },
    "& .PhoneInputCountry": {
        marginRight: '8px',
        cursor: 'pointer'
    },
    '& .PhoneInputCountrySelectArrow': {
        color: '#53503F',
        borderLeft: '0px',
        borderTop: '0px',
        borderWidth: '2.8px',
        opacity: 2,
        fontSize: '22px',
        borderRadius: '.5px',
        marginLeft: '6.41px',
        cursor: 'pointer',
        display:'none'
    },

    '@media (max-width:600px)': {
        minWidth: "unset"
    },
   

});

const styles = {
    errorText: {
        fontWeight: 600,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '12px',
        color: "#DC2626",
        position:'absolute',
        lineHeight:'normal'
    },
    add_address: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '24px',
        fontWeight: 600,
        color: '#53503F',
    },
    confirmation: {
        fontSize: '22px',
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 600,
        color: '#53503F',
    },
   
    colorBox: {
        height: '12px',
        width: '12px',
        borderRadius: '2px',
        backgroundColor: "blue"
    },
    crossIcon: {
        cursor: 'pointer'
    }
}
// Customizable Area End
