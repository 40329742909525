import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData ,removeStorageData} from "../../../framework/src/Utilities";
import { isValidPhoneNumber } from "react-phone-number-input";


// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface ShoppingCartItem {
  id?: number,
  type?: string,
  attributes: {
    id: number,
    catalogue_id?: number,
    catalogue_variant_id?: number,
    quantity: number,
    name?: string,
    description?: string,
    model_number?: string,
    variant_color?: string,
    variant_size?: string,
    variant_qty?: number,
    approved_price?: string,
    price?: string,
    is_offer_available?: boolean,
    image_url?: string


  };
}
export interface emptyCart {

  id: number,
  name: string,
  catalogues:
  {
    id: number,
    type: string,
    attributes: {
      id: number,
      catalogue_id: number,
      created_at: string,
      updated_at: string,
      name: string,
      category_id: number,
      sub_category_id: number,
      description: string,
      price: string,
      brand: string,
      currency: string,
      image_url: string,
      is_favorited:boolean
    }
  }[]

}
export interface categoriesArrayInterface {

  id?: number,
  type?: string,
  attributes?: {
    id?: number,
    name?: string,
    rank?: string | null,
    created_at?: string,
    updated_at?: string,
    brands?: string | null,
    logo?: string
  }

}
export interface categoriesOnHoverListDataInterface {

  Men: {
    sub_category_id: number,
    name: string
  }[],
  Women: {
    sub_category_id: number,
    name: string
  }[],


  NewArrivals: {
    id: number,
    name: string
  }[]

 

}
export interface countryInterFace {
  id:number,
  attributes:{name:string,alpha2: string,country_code:number,id:number}
  value: number,
  label: string,
  countryCode: string,
}
export interface countryInterArr{
  value?: number|string,
  label?: string,
  countryCode?:string,
  country_id?:number,
  contCode?:number
}
export interface StateList{
  label?: string
  value?: string
}
export interface addressListInterFace{
  id: number|string,
  type: string,
  attributes: {
    latitude: string,
    longitude: string,
    address_line_1: string,
    address_line_2: string,
    city: string,
    state: string,
    country: string,
    postal_code: number,
    is_activated: boolean
}
}
// Customizable Area End

interface S {
  // Customizable Area Start

  token: string;
  cartData: ShoppingCartItem[],
  cart: {
    data: {
      id: number | string,
      type: string,
      attributes: {
        uuid: string,
        status: string,
        customer_id: number | string,
        total_items: number | string,
        order_items: ShoppingCartItem[]
      }
    },
    meta: {
      summary: {
        delivery_charge: string,
        tax_percentage: string,
        platform_fees: string,
        subtotal_price: string,
        total_price: string
      },
      message: string
    }
  },
  emptyCartData: emptyCart[]
  categoriesArrayData: categoriesArrayInterface[]
  categoriesOnHoverListData: categoriesOnHoverListDataInterface[]
  isAddressModalOpen: boolean
  address_linr_1: string,
  address_linr_2: string,
  city: countryInterArr|undefined|null,
  state: countryInterArr|undefined|null,
  country_id: countryInterArr|undefined|null,
  postal_code: string,
  postal_codeErr: boolean,
  postal_codeErrText:string,
  is_activated: boolean,
  countryListArray:countryInterArr[]
  cityListArray:countryInterArr[]
  stateListArray:StateList[]
  phoneNumber:string
  phoneNumberErr:boolean
  isChecked:boolean
  addressList:addressListInterFace[]
  isCartRemoveModalOpen:boolean
  cartIdRemove:number|string
  selectedAddressId:number|string
  uuId:string,
 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cartDataApiCallId?: string;
  emptyCartDataApiCallId?: string;
  categoriesOnHoverListDataApi?: string;
  categoriesArrayDataApi?: string;
  countryListApi?:string;
  cityListApi?:string;
  postAddressApi?:string;
  getAddressApi?:string;
  addQuantityApi?:string;
  removeItemApi?:string;
  moveToFavoriteApi?:string;
  stateListApi?:string;
  addToFavoriteApi?:string
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start

      token: "",
      cartData: [],
      cart: {
        data: {
          id: '',
          type: '',
          attributes: {
            uuid: '',
            status: '',
            customer_id: '',
            total_items: '',
            order_items: []
          }
        },
        meta: {
          summary: {
            delivery_charge: '',
            tax_percentage: '',
            platform_fees: '',
            subtotal_price: '',
            total_price: ''
          },
          message: ''
        }
      },
      emptyCartData: [],
      categoriesArrayData: [],
      categoriesOnHoverListData: [],
      isAddressModalOpen: false,
      address_linr_1: '',
      address_linr_2: '',
      city: null,
      state:null,
      country_id: null,
      postal_code: '',
      postal_codeErr:false,
      postal_codeErrText:'',
      is_activated: false,
      countryListArray:[],
      cityListArray:[],
      stateListArray:[],
      phoneNumber:'',
      phoneNumberErr:false,
      isChecked:false,
      addressList:[],
      isCartRemoveModalOpen:false,
      cartIdRemove:'',
      selectedAddressId:'',
      uuId:'',
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.handleFetchCartData()
    this.handleFetchDataForEmptyCart()
    this.getcategoriesArrayData()
    this.handleFetchCountryList()
    this.handleGetAddressList()
    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });

    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson?.errors) {
      switch (apiRequestCallId) {
        case this.cartDataApiCallId: this.setState({ cartData: responseJson?.data?.attributes?.order_items, cart: responseJson })
          break;
        case this.emptyCartDataApiCallId: this.setState({ emptyCartData: responseJson?.categories })
          break;
        case this.categoriesOnHoverListDataApi: this.setState({ categoriesOnHoverListData: responseJson })
          break;
        case this.categoriesArrayDataApi: this.setState({ categoriesArrayData: responseJson?.data })
          break;
        case this.countryListApi: this.handeCountryArray(responseJson?.data) 
          break;
        case this.cityListApi: this.handeCityArray(responseJson?.cities)
          break;
        case this.stateListApi:this.handleStateMap(responseJson?.states)
          break;
        case this.postAddressApi: this.setState({ isAddressModalOpen: false, address_linr_1: '', address_linr_2: '', city: null, state: null, country_id: null, postal_code: '', isChecked: false, phoneNumber: '' },()=>this.handleGetAddressList())
          break;
        case this.getAddressApi: this.setState({addressList:responseJson?.data})
         this.handleSelectedAddressId(responseJson?.data)
          break;
        case this.addQuantityApi: this.handleFetchCartData()
          break;
        case this.removeItemApi: this.handleFetchCartData()
          this.setState({ isCartRemoveModalOpen: false, cartIdRemove: '', uuId: '' })
          break;
        case this.moveToFavoriteApi: this.handleRemoveItemCart()
          break;
        case this.addToFavoriteApi: this.handleFetchDataForEmptyCart()
          break;
      }
    }
    if (responseJson && responseJson?.errors) {
      if (apiRequestCallId === this.postAddressApi) {
        this.setState({ postal_codeErr: true, postal_codeErrText: responseJson.errors[0].postal_code })
      }
      if(apiRequestCallId=== this.cartDataApiCallId){
        if(responseJson.errors[0]?.token){
          removeStorageData('loginToken')
        }
      }

    }
    // Customizable Area End
  };

  // Customizable Area Start


  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };
  handleFetchCartData = async () => {
    const cartId=await getStorageData('cartId')
    const tokenIs= await getStorageData('loginToken')

    const header = {
      "Content-Type": configJSON.apiContentType,
      ...(tokenIs&&{token: tokenIs})
     
    }
 
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cartDataApiCallId = requestMessage1.messageId;

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointCart(cartId)
    );
    

    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage1.id, requestMessage1);
  }
  handleFetchDataForEmptyCart = async () => {
    const tokenIs= await getStorageData('loginToken')

    const header = {
      "Content-Type": configJSON.apiContentType,
      ...(tokenIs&&{token: tokenIs})
     
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emptyCartDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointCartEmptyCart
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleReturnZero = (itemNumber: number | string) => {
    return Number(itemNumber) > 0 && Number(itemNumber) < 10 ? 0 : ''
  }
  getCategoriesOnHoverData = (categorID: string | number) => {
    if (categorID != 0) {
      const requestMessageData = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.categoriesOnHoverListDataApi = requestMessageData.messageId;
      requestMessageData.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      requestMessageData.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointcategories_hoverData(categorID)
      );
      runEngine.sendMessage(requestMessageData.id, requestMessageData);

    }
  }
  getcategoriesArrayData = () => {
    const requestMessageDataCateg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesArrayDataApi = requestMessageDataCateg.messageId;
    requestMessageDataCateg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessageDataCateg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointgetcategoriesArrayData
    );
    runEngine.sendMessage(requestMessageDataCateg.id, requestMessageDataCateg);

  }
  handleFetchCountryList = () => {
    const requestMessageCountryList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryListApi = requestMessageCountryList.messageId;
    requestMessageCountryList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessageCountryList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListEndPoint
    );
    runEngine.sendMessage(requestMessageCountryList.id, requestMessageCountryList);

  }
  handeCountryArray = (data: countryInterFace[]) => {
    const newData = data.map((element: countryInterFace) => ({
      value: element.id,
      label: element?.attributes.name,
      countryCode: element?.attributes?.alpha2,
      country_id: element?.attributes?.id,
      contCode:element?.attributes?.country_code
    }));
    this.setState({ countryListArray: newData });
  }
  handeCityArray = (data: []) => {
   
    const newData = data.map((element: string) => ({
      value: element,
      label: element,

    }));
    this.setState({cityListArray:newData});
  }
  handleStateMap=(data:StateList)=>{
  
   const newState=Object.entries(data)?.map(([key,value])=>({
    value: key,
    label: value
  }))
  this.setState({stateListArray:newState})
  }
  handleChangeInput = (value: string, state: string) => {

    this.setState((prevState) => ({ ...prevState, [state]: value ,...(state=='postal_code'&&{postal_codeErr:false,postal_codeErrText:''})}))
  }
  handleChangeSelect = (event: countryInterArr | StateList |{} | undefined | null, state: string) => {
    if(state==='state'){
      this.setState({state:event},()=>this.handleFetchCityByState(event))
    }else{

      this.setState((prevState) => ({ ...prevState, [state]: event,...(state==='country_id'&&{phoneNumber:'',phoneNumberErr:false})}), () => this.handleFetchCity(state, event))
    }
  }
  handleChangePhoneNumber = (event: string | undefined) => {
    
    if (event) {
      this.setState({ phoneNumber: event, phoneNumberErr: !isValidPhoneNumber(event) })
    }
  }
  handleFetchCity = (state: string, event: countryInterArr | {} | undefined | null) => {

    if (state == 'country_id') {
      this.setState({ cityListArray: [], city: null, stateListArray: [], state: null })
      this.handleFetchState(event)
    }
  }
  handleFetchCityByState = (event:countryInterArr | StateList  | undefined | null) =>{
    const requestMessageCity = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cityListApi = requestMessageCity.messageId;
    requestMessageCity.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessageCity.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cityListEndPoint(this.state.country_id?.countryCode,event?.value)
    );
    runEngine.sendMessage(requestMessageCity.id, requestMessageCity);
  }
  handleFetchState=(event:countryInterArr | {contCode:number,countryCode:string} | undefined | null)=>{

    const requestMessageStateList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateListApi = requestMessageStateList.messageId;
    requestMessageStateList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessageStateList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stateEndPoint(event?.countryCode)
    );
    runEngine.sendMessage(requestMessageStateList.id, requestMessageStateList);
  }
  checkForDisableSave = () => {
    let country = this.state.country_id == undefined || this.state.country_id == null
    let state = this.state.state == undefined || this.state.state == null
    let city = this.state.city == undefined || this.state.city == null
    let postal_code = this.state.postal_code == undefined || this.state.postal_code == null || this.state.postal_code.trim() == ''
    return this.handleDisbale(country,state,city,postal_code)
    
  }
  handleDisbale =(country:boolean,state:boolean,city:boolean,postal_code:boolean) =>{
    if (country ){
      return true
     
   } else{
      if(state&&this.state.stateListArray.length>0){
        return true
      }else if(!state&&city){
        return true
      }else if(postal_code){
        return true
      }else{
        return false
      }
   }
  }
  handleSubmitAddress = async () => {
    if (!this.state.phoneNumberErr) {
      
      const body = {

        ...(this.state.address_linr_1?.trim() !== '' && { "address_line_1": this.state.address_linr_1 }),
        ...(this.state.address_linr_2?.trim() !== '' && { "address_line_2": this.state.address_linr_2 }),
        "city": this.state.city?.value == undefined ? 'nil' : this.state.city?.value,
        "state": this.state.state?.value == undefined ? 'nil' : this.state.state?.value,
        "country_id": this.state.country_id?.country_id,
        "postal_code": this.state.postal_code,
        ...(this.state.phoneNumber?.trim() !== '' && { "phone_number": this.state.phoneNumber }),
        "is_activated": this.state.isChecked

      } 
     
      const tokenIs= await getStorageData('loginToken')
       
      const header = {
        "Content-Type": configJSON.apiContentType,
      ...(tokenIs&&{token: tokenIs})
      };
      const requestMessagePostAddress = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postAddressApi = requestMessagePostAddress.messageId;
      requestMessagePostAddress.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      requestMessagePostAddress.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addressEndPoint
      );
      requestMessagePostAddress.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessagePostAddress.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessagePostAddress.id, requestMessagePostAddress);
    }
  }
  handleGetAddressList = async () => {
    const tokenIs= await getStorageData('loginToken')
    
    const header = {
      "Content-Type": configJSON.apiContentType,
     ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageAddressList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddressApi = requestMessageAddressList.messageId;
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressEndPoint
    );
    requestMessageAddressList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header))
    runEngine.sendMessage(requestMessageAddressList.id, requestMessageAddressList);
  }
  handleAddQuantityCart = async (uuid:string,id:number|undefined) => {
    const body = {
      "id": id,
      "cart_id": uuid
    }
    const tokenIs=await getStorageData('loginToken')
    const header = {
      "Content-Type": configJSON.apiContentType,
      ...(tokenIs&&{token: tokenIs})
    };
    const requestMessagePostAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addQuantityApi = requestMessagePostAddress.messageId;
    requestMessagePostAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessagePostAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.add_quantityEndPoint
    );
    requestMessagePostAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagePostAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessagePostAddress.id, requestMessagePostAddress);
  }
  handleRemoveQuantityCart = async (uuid:string,id:number|undefined,count:number) => {
    if(count>1){

   
    const body = {
      "id": id,
      "cart_id": uuid
    }
    const tokenIs= await getStorageData('loginToken')
    
    const header = {
      "Content-Type": configJSON.apiContentType,
       ...(tokenIs&&{token: tokenIs})
    };
    const requestMessagePostAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addQuantityApi = requestMessagePostAddress.messageId;
    requestMessagePostAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessagePostAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.remove_quantityEndPoint
    );
    requestMessagePostAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessagePostAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessagePostAddress.id, requestMessagePostAddress);
  }
}
  handleRemoveItemCart = async () => {
    const body = {
      "cart_id": this.state.uuId
    }
    const tokenIs= await getStorageData('loginToken')
    
    const header = {
      "Content-Type": configJSON.apiContentType,
     ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageRemoveItem = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeItemApi = requestMessageRemoveItem.messageId;
    requestMessageRemoveItem.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessageRemoveItem.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeItemCartEndPointe(this.state.cartIdRemove)
    );
    requestMessageRemoveItem.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageRemoveItem.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    runEngine.sendMessage(requestMessageRemoveItem.id, requestMessageRemoveItem);

  }
  handleSelectedAddressId=(data:addressListInterFace[])=>{
    data.forEach((element)=>{
      if(element?.attributes?.is_activated){
       this.setState({selectedAddressId:element.id}) 
      }
    
    })
  }
  handleMoveToFavorite=async(catalogue_variant_id:number|undefined)=>{
    const body = {
      "wishlist": {
        "catalogue_variant_id": catalogue_variant_id
        }
    }
    const tokenIs= await getStorageData('loginToken')
  
    const header = {
      "Content-Type": configJSON.apiContentType,
       ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageMoveToFavorite = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.moveToFavoriteApi = requestMessageMoveToFavorite.messageId;
    requestMessageMoveToFavorite.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessageMoveToFavorite.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveToFavorite
    );
    requestMessageMoveToFavorite.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageMoveToFavorite.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageMoveToFavorite.id, requestMessageMoveToFavorite);
  }
  handleAddToFavorite=async(catalogue_variant_id:number)=>{
    
    const bodyFav = {
      "wishlist": {
        "catalogue_variant_id": catalogue_variant_id
        }
    }

    const tokenIs= await getStorageData('loginToken')
  
    const headerIs = {
      "Content-Type": configJSON.apiContentType,
       ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageAddToFavorite = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToFavoriteApi = requestMessageAddToFavorite.messageId;
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveToFavorite
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerIs)
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyFav)
    );
    runEngine.sendMessage(requestMessageAddToFavorite.id, requestMessageAddToFavorite);
  

  }
  handleNavigate=(path:string)=>{
    const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
    msgsIs.addData(getName(MessageEnum.NavigationTargetMessage),path);
    msgsIs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsIs)
  }
  handleCategoryData = (categoryName: string, categoryId: number) => {

  }
  // Customizable Area End
}
