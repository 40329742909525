//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";


// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: { name: string; price: number; description: string; images:{url:string}[] };
  isloading: boolean;
  focusedImageIndex: number;
  product_id:number
 categoriesArray:any[];
 categoriesOnHoverList:any;
 productDescriptionData:any;
 featuredProducts:any[];
 productId:string
 openCharityModal:boolean;
 charityPolicyData:any;
 openOfferModal:boolean;
 makeOffer:string;
 offerErr:string;
 offerSuccessModal:boolean;
 selectedColor:any;
 selectedSize:any;
 addToCartErr:string,
 bigImageData:any,
 disableAddtoCartBtn:boolean;
 categoryId: number,
 categoryName: string,
 categoryData: any[],
 cartLength:number;
 allFilter: any,
 showCategoryPage: boolean,
 filterData: any,
 gender: any,
 category_id:any;
 openLoginModal:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  getCategoriesListApiCallId:string="";
  getHoverCategoriesList:string="";
  addtoFavorite:string="";
  getProductDescriptionAPIId:string="";
  getLandingPagesApiCallId:string="";
  addToCartApi:string=""
  getTermsCondsCallId:string="";
  makeOfferAPIId:string="";
  categorydataApiId:string="";
  cartDataApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      isloading: false,
      productData: { name: "", price: 1, description: "", images: [{url:""}] },
      product_id: 8,
      focusedImageIndex:1,
      categoriesArray:[],
      categoriesOnHoverList:{},
      productDescriptionData:{},
      featuredProducts:[],
      productId:"",
      openCharityModal:false,
      charityPolicyData:{},
      openOfferModal:false,
      makeOffer:"",
      offerErr:"",
      offerSuccessModal:false,
      selectedColor:null,
      selectedSize:null,
      addToCartErr:"",
      bigImageData:{},
      categoryData: [],
      cartLength:0,
      showCategoryPage: false,
      disableAddtoCartBtn:false,
      category_id:"",
      openLoginModal:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id || !apiRequestCallId) {
      this.parseApiErrorResponse(errorReponse);
      return;
    }
    
    switch (apiRequestCallId) {
      case this.getCategoriesListApiCallId:
        this.setState({ categoriesArray: responseJson.data });
        break;
      
      case this.getHoverCategoriesList:
        this.setState({ categoriesOnHoverList: responseJson });
        break;
        case this.addtoFavorite:
          this.getProductDescriptionDetails(this.state.productId)
        break;
      
      case this.getProductDescriptionAPIId:
        this.setState(
          { productDescriptionData:responseJson },this.updateAfterProductDescription)
        break;
      
      case this.getLandingPagesApiCallId:
        this.setState({ featuredProducts: responseJson?.data });
        break;
      
      case this.getTermsCondsCallId:
        const charityPolicy = responseJson.data.find(ele => ele.condition_type === "charity");
        if (charityPolicy) {
          this.setState({ charityPolicyData: charityPolicy.description });
        }
        break;
      
      case this.addToCartApi:
        if(!responseJson.errors && responseJson.message){
          this.setState({disableAddtoCartBtn:true},()=>{this.fetchCartData()})
        }else{
          this.setState({disableAddtoCartBtn:true,})
        }
        await setStorageData("cartId", responseJson?.id);
        await setStorageData("uuId", responseJson?.uuid);
        break;
      
      case this.makeOfferAPIId:
        if (responseJson.message) {
          this.setState({ openOfferModal: false, makeOffer: "" }, () => {
            this.setState({ offerSuccessModal: true });
          });
        } if (responseJson.errors) {
          this.setState({ offerErr: responseJson.errors[0].message });
        }
        break;
        case this.categorydataApiId:
          this.setState({categoryData:responseJson.data})
          break;
        case this.cartDataApiCallId:
           this.setState({ cartLength: responseJson?.data?.attributes?.order_items.length})
        break;
      
      default:
        this.parseApiErrorResponse(errorReponse);
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const productId=new URLSearchParams(window.location.search).get('productId')
    this.setState({productId:productId})
    super.componentDidMount();
    this.getCategoriesList()
    this.getTermsConds("")
    this.getProductDescriptionDetails(this.state.productId||productId)
    this.fetchCartData();
  }
  async componentDidUpdate(prevProps, prevState) {
    const searchParams = new URLSearchParams(window.location.search);
    const currentProductId = searchParams.get('productId');
    if (prevState.productId !== currentProductId) {
      this.setState({ productId: currentProductId,showCategoryPage:false,disableAddtoCartBtn:false });
      this.getProductDescriptionDetails(currentProductId);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
 updateAfterProductDescription= ()=>{
    this.setState({addToCartErr:"",bigImageData:this.state.productDescriptionData?.catalogue?.attributes?.attachments?.[0]})
    this.getSimilarProducts()           
      this.setSelectedColor();
      this.setSelectedSize();
  }
  getProductDescriptionDetails=async(productId:string)=>{
    const token=await getStorageData("loginToken")
    this.getProductDescriptionAPIId= this.apiCall(token,{
      method:"GET",
      endPoint:`bx_block_productdescription/productdescriptions/${productId}`
  })
  }

  fetchCartData=async()=>{
    const cartId=await getStorageData('cartId')
    const tokenIs= await getStorageData('loginToken')
    this.cartDataApiCallId= this.apiCall(tokenIs,{
      method:"GET",
      endPoint:`bx_block_shopping_cart/orders/${cartId}`,
  })
  }
  addToCart=async()=>{
    const {selectedColor,selectedSize}=this.state
    if(selectedColor &&selectedSize){
      const token=await getStorageData("loginToken")
      const body={
        "data": {
          "catalogue_variant_id":Number(this.state.productDescriptionData?.catalogue?.attributes?.catalogue_variant_id),
          "catalogue_id": Number(this.state.productDescriptionData?.catalogue?.id)
        }
      }
      this.addToCartApi= this.apiCall(token,{
        method:"POST",
        endPoint:`bx_block_shopping_cart/order_items`,
        body:JSON.stringify(body)
    })
    }else{
      this.setState({addToCartErr:"select size and color first"})
    }
   
  }
  handleCategoryClick=(category:any)=>{
    const {category_id,category_name}=category
    this.handleCategoryData(category_name,category_id)
  }
  getSimilarProducts=async()=>{
    const token=await getStorageData("loginToken")
    this.getLandingPagesApiCallId= this.apiCall(token,{
      method:"GET",
      endPoint:`/bx_block_catalogue/catalogues/similar_catalogues?catalogue_id=${this.state.productDescriptionData?.catalogue?.id}&category_id=${this.state.productDescriptionData?.catalogue?.attributes?.category_id}`
  })
  }
  navigateToDetailedProductView=(productId:string)=>{
    this.props.navigation.navigate("ProductDescription",{productId:productId,path:{productId:productId}})
  }
  getTermsConds = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_terms_and_conditions/terms_and_conditions'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCategoriesList=()=>{
    this.getCategoriesListApiCallId= this.apiCall("",{
      method:"GET",
      endPoint:"/bx_block_categories/categories"
  })
  }
  makeAnOfferChange=(e:any)=>{
    this.setState({makeOffer:e.target.value})
  }
  makeAnOffer=async()=>{
    const body={
      "offer_request": {
        "catalogue_variant_id":Number(this.state.productDescriptionData?.catalogue?.attributes?.catalogue_variant_id),
        "catalogue_id": Number(this.state.productDescriptionData?.catalogue?.id),
          "offer_price": Number(this.state.makeOffer)
      }
  }
  const token=await getStorageData("loginToken")
    this.makeOfferAPIId= this.apiCall(token,{
      method:"POST",
      endPoint:"/bx_block_request_management/offer_requests",
      body:JSON.stringify(body)
  })
  }
  handleChange=(event: any|{} | undefined | null, state: string) => {
    this.setState((prevState) => ({ ...prevState, [state]: event }))
  }
  handleCategoryData = (categoryName: any, categoryId: any) => {
    this.setState({showCategoryPage: true, categoryId: categoryId, categoryName: categoryName})
    this.categorydataApiId=this.apiCall("",{
      method:"GET",
      endPoint:`bx_block_catalogue/catalogues/filtered_catalogues?category_id=${categoryId}`
    })
  }
  handleAllFilter = () => {
    this.setState({allFilter: true})
  }
  handleCloseAllFilter = () => {
    this.setState({allFilter: false})
  }
  handleCategoryVisibility = () => {
    this.setState({showCategoryPage: false})
  }
  handleApplyButton = (data: any) => {
    this.setState({filterData: data})
  }
 
  downloadFile = () => {
    const file = this.state.productDescriptionData?.catalogue?.attributes?.certificates[0];
    const fileUrl = file?.url;
    const fileName = "certificate.pdf";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  setSelectedColor = () => {
    if (!this.state.productDescriptionData?.catalogue?.attributes?.color_variants) return;
    const selectedColor =this.state.productDescriptionData?.catalogue?.attributes?.color_variants.find((item) => item.attributes.selected === true);
    if (selectedColor && this.state.selectedColor?.value !== selectedColor.attributes.id) {
      this.setState({
        selectedColor: {
          value: selectedColor.attributes.id,
          label: selectedColor.attributes.name,
          colorCode: selectedColor.attributes.color_code,
        },
      });
    }
  };
  
  setSelectedSize = () => {
    if (!this.state.productDescriptionData?.catalogue?.attributes?.size_variants) return;
  
    const selectedSize = this.state.productDescriptionData?.catalogue?.attributes?.size_variants.find((item) => item.attributes.selected === true);
    if (selectedSize && this.state.selectedSize?.value !== selectedSize.attributes.id) {
      this.setState({
        selectedSize: {
          value: selectedSize.attributes.id,
          label: selectedSize.attributes.name,
        },
      });
    }
  };
  
  getColorOptions=()=>{
    const options = this.state.productDescriptionData?.catalogue?.attributes?.color_variants?.map(item => ({
      value: item.attributes.id,
      label: item.attributes.name,
      colorCode: item.attributes.color_code
    }));
    return options
  }
  handleCharityModal=()=>{
this.setState({openCharityModal:!this.state.openCharityModal})
  }
  handleAddToFavorite=async(catalogue_variant_id:any)=>{
    const token=await getStorageData("loginToken");
    const body={
        "wishlist": {
          "catalogue_variant_id": catalogue_variant_id
          }
    }
    this.addtoFavorite=this.apiCall(token,{
      method:"POST",
      endPoint:`/bx_block_wishlist/wishlists/add_favorite_or_remove`,
      body:JSON.stringify(body)
    })
  }
 getSizeOptions=()=>{

   const sizeOptions = this.state.productDescriptionData?.catalogue?.attributes?.size_variants?.map(item => ({
     value: item.attributes.id,
     label: item.attributes.name,
     colorCode: item.attributes.color_code,
     selected:item.attributes.selected
    }));
  return sizeOptions
  }
  getCategoriesOnHover=(categorID:string|number)=>{
    if(categorID!=0){
      this.getHoverCategoriesList=this.apiCall("",{
        method:"GET",
        endPoint:`/bx_block_categories/categories/categories_hover_options?category_id=${categorID}`
      })
    }
  }

  apiCall = (token: any,payload:any) => {
    let {method,endPoint,body}=payload
    const header = {
      'Content-Type': 'application/json',
      token: token||"",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body&&requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   return requestMessage.messageId;

  };
  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };
  getPostData(): boolean {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productApiItemCallId = requestMessage.messageId;
    let api_url = `/${configJSON.productAPiEndPoint}/${this.state.product_id}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      api_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleMakeOfferModal=async()=>{
    const {selectedColor,selectedSize}=this.state
    const loginToken=await getStorageData("loginToken");
    const loginDetails=await getStorageData("loginDetails");

    if(selectedColor &&selectedSize && loginDetails && loginToken){
      this.setState({openOfferModal:!this.state.openOfferModal,offerErr:"",makeOffer:"",openLoginModal:false})
    }
    else{
      this.setState({addToCartErr:"select size and color first",openLoginModal:true})
    }
  }
  closeLoginModal=()=>{
    this.setState({openLoginModal:false})
  }
  handleOfferSuccess=()=>{
    this.setState({offerSuccessModal:false})
  }
  handleImageClick=(imagedata:any)=>{
    this.setState({bigImageData:imagedata})
  }
  // Customizable Area End
}
